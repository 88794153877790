import { DynamicEnvironment } from "./dynamic-environment";

class Environment extends DynamicEnvironment {

  public production: boolean;

  public API_ROOT_URL: string;
  public buildPublic: boolean;
  public buildPortal: boolean;

  constructor() {
    super();
    this.production = false;

    this.API_ROOT_URL = 'https://billionb.dev-asha.com';
    this.buildPublic = true;
    this.buildPortal = true;
  }
}

export const environment = new Environment();
export enum CryptoKeyConstant {

    Login = "testLogin",

    Role = "roletest",

    Authen =  "authen",

    Search = "search",

    Reauthen = "testCipher",

}
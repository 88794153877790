import * as CryptoJS from 'crypto-js';

export class EncryptDecryptUtil {

    static encryptAES(value: string, key: string): string {
        let encrypt = "";
        try {
            encrypt = CryptoJS.AES.encrypt(value, key).toString();
        } catch (e) {
            console.warn(e);
        }
        return encrypt;
    }

    static decryptAES(value: string, key: string): string {
        let decrypt = "";
        try {
            const bytes = CryptoJS.AES.decrypt(value, key);
            if (bytes.toString()) {
                decrypt = bytes.toString(CryptoJS.enc.Utf8);
            }
            return decrypt;
        } catch (e) {
            console.warn(e);
        }
        return decrypt;

    }

    static encryptMassage(message: string): string {
        return btoa(message);
    }

    static decryptMassage(message: string): string {
        return atob(message);
    }

    static encryptAESObject(value: any, key: string): string {
        let encrypt = "";
        try {
            encrypt = CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();
        } catch (e) {
            console.warn(e);
        }
        return encrypt;
    }

    static decryptAESObject(value: any, key: string): any {
        let decrypt = "";
        try {
            const bytes = CryptoJS.AES.decrypt(value, key);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return value;
        } catch (e) {
            console.warn(e);
        }
        return decrypt;

    }

}
import { HomeService } from 'src/app/@core/services/home.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PUBLIC_ROUTES, ROOT_ROUTES } from 'src/app/@core/constants/routes.constant';

@Component({
  selector: 'app-subscribe-footnote',
  templateUrl: './subscribe-footnote.component.html',
  styleUrls: ['./subscribe-footnote.component.scss']
})
export class SubscribeFootnoteComponent implements OnInit {

  email: string = '';

  detail: string = '';
  image: string = '';
  url: string = 'http://billion-prop.dev-asha9.com/course';
  text_btn: string = '';
  title: string = '';

  constructor(
    private router: Router,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getBannerfooter();
  }

  goCourse() {
    window.open(this.url);
    // this.router.navigate(['/', PUBLIC_ROUTES.course]);
  }

  getBannerfooter(){
    this.homeService.get_banner_footer().then(resp => {
      this.detail = resp.banner_footer_detail;
      this.image = resp.banner_footer_image;
      this.title = resp.banner_footer_title;
      this.text_btn = resp.banner_footer_text_button;
      // this.url = resp.banner_footer_url;
    })
  }

}

import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { TranslateUtil } from "./translate.util";

export class NotificationUtil {

    static options: SweetAlertOptions = {
        padding: 30,
        confirmButtonText: 'ตกลง',
    }

    static async success(title: string = 'บันทึกสำเร็จ', html: string = ''): Promise<SweetAlertResult> {
        return Swal.fire({
            padding: 30,
            confirmButtonText: await TranslateUtil.translateText('Ok'),
            // icon: 'success',
            title: await TranslateUtil.translateText(title),
            html: await TranslateUtil.translateText(html),
            customClass: {
                container: 'popup-result-success' 
            }
        });
    }

    static async invalid(title: string = '', html: string = ''): Promise<SweetAlertResult> {
        return Swal.fire({
            padding: 30,
            confirmButtonText: await TranslateUtil.translateText('Ok'),
            icon: 'error',
            title: await TranslateUtil.translateText(title),
            html: await TranslateUtil.translateText(html),
        });
    }

    // static confirm(title: string = '', html: string = ''): Promise<SweetAlertResult> {
    //     return Swal.fire({
    //         ...this.options,
    //         icon: 'question',
    //         title,
    //         html,
    //         confirmButtonText: 'ยืนยัน',
    //         cancelButtonText: 'ยกเลิก',
    //         showCancelButton: true,
    //         customClass: {
    //             content: 'popup-content'
    //         },
    //     });
    // }
}
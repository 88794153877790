import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, TimeoutError } from "rxjs";
import { Observable, throwError } from "rxjs";
import { catchError, retryWhen, switchMap, tap, timeout } from "rxjs/operators";
import { BroadcastService } from "../services/bordcast.service";
import { formatDate } from "@angular/common";
import { NotificationUtil } from "./notification.util";
import { FileType } from "../constants/global.constant";
import { ApiDisplayConfigService } from "../services/api-display-config.service";
import { ResponseHeaderModel } from "../models/response-model";
import { WhitelistErrors, WhitelistRetry } from "../constants/whitelist-errors";
import { AuthenService } from "../services/auth.service";
import { LoginService } from "../services/login.service";

const SUCCESS = 'SUCCESS';
const POST = 'POST';

/** 3 min */
const HTTP_TIMEOUT = 3 * 60000;

const ERROR_MESSAGES = {
    TIMEOUT: 'คำขอหมดเวลา',
    STATUS_4XX: 'คำขอผิดพลาด',
    STATUS_5XX: 'พบข้อผิดพลาดจากเซิร์ฟเวอร์',
    INTERNET_IS_REQUIRED: 'พบปัญหาการเชื่อมต่ออินเตอร์เน็ต',
    DEFAULT: 'พบปัญหาการเชื่อมต่อ',
}

/** retry limit default 3 */
let retryLimit: number = 3;
let attempt: number = 0;

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    public http404 = false;

    public showError = true;

    constructor(
        private broadcastService: BroadcastService,
        private loginService: LoginService,
        private apiDisplayConfigService: ApiDisplayConfigService,
        private authService: AuthenService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        attempt = 0;
        request = request.clone({
            setHeaders: {
                //   'Content-Type' : request.url.includes('upload_images')? 'multipart/form-data; boundary=----WebKitFormBoundaryL2AaZAzyhZKNOIQ5':'application/json; charset=utf-8',
                'Accept': 'application/json, text/plain, */*',
                'Authorization': `Bearer ${this.authService.getToken()}`,
            },
        });
        if (!window.navigator.onLine) {
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            NotificationUtil.invalid('', ERROR_MESSAGES.INTERNET_IS_REQUIRED);
            this.broadcastService.http404.next(true);
            return Observable.throw(new HttpErrorResponse({ error: 'Internet is required.' }));

        }
        return next.handle(request).pipe(
            switchMap(async (event: any) => {

                if (event?.body instanceof Blob) {
                    const jsonParseBody = this.tryJsonParseString(await event.body.text());
                    if (jsonParseBody?.respHeader?.errorCode) {
                        event.body = jsonParseBody;
                    }
                }
                return event;
            }),
            switchMap((event: any) => {
                const contentTypeFile = Object.values(FileType).includes(event?.headers?.get('content-type'));
                if (event instanceof HttpResponse && !contentTypeFile) {
                    if (event.status !== 200 && event.status !== 201) {
                        return throwError(event.body.respHeader as ResponseHeaderModel);
                    }
                    else {
                        this.showError = true;
                    }
                }
                return of(event);
            }),
            retryWhen(errors =>
                errors.pipe(
                    /** ตัวที่จะให้ retry เพิ่มไว้ที่ WhitelistRetry service name */
                    // filter(() => WhitelistRetry.ServiceName.includes(request?.body?.reqHeader?.serviceName)),
                    tap(error => {
                        if (WhitelistRetry.ServiceName.includes(request?.body?.reqHeader?.serviceName) && !WhitelistRetry.ExceptErrorCode.includes(error.errorCode)) {
                            if (attempt++ < retryLimit) {
                                console.warn(`Service ${request?.body?.reqHeader?.serviceName} Retry : ${attempt}/${retryLimit}`);
                                return throwError(error);
                            }
                        }
                        // retryLimit = this.getConfigRetryLimit();
                        throw error;

                    }),
                )
            ),
            timeout(HTTP_TIMEOUT),
            catchError((error: any) => {
                let errorMessage = this.getErrorMessage(error);
                /** เช็ค Whitelist ErrorCode ที่ไม่จำเป็นต้อง popup error */
                const notInWhitelist = !WhitelistErrors.ErrorCode.includes(error?.errorCode)
                    && !WhitelistErrors.ServiceName.includes(error?.serviceName)
                    && !(window.location.hash == '#/intro-page')
                if (notInWhitelist && error.status != 0 && this.showError === true) {
                    NotificationUtil.invalid('', errorMessage);
                }
                if (error.status === 401 && error.url != 'https://billionb.dev-asha.com/api/login_member') {
                    this.showError = false;
                    this.loginService.logout();
                    this.loginService.removeSessionStorage();
                    this.loginService.isLogOut = true;
                }
                return throwError(error);
            }));
    }

    private getErrorMessage(error: any) {
        let errorMessage = '';
        if (error?.errorCode) {
            /** ถ้ามี error ที่ได้จาก api อยู่แล้วให้ใช้ message นี้เลย */
            return error?.errorDesc + " (" + error?.errorCode + ")";
        } else if (error instanceof TimeoutError) {
            /** error timeout (3 min) */
            errorMessage = ERROR_MESSAGES.TIMEOUT;
        } else if (error instanceof HttpErrorResponse) {
            /** error HttpErrorResponse deafult */
            errorMessage = ERROR_MESSAGES.DEFAULT;

            const httpStatus: string = error.status.toString();
            if (httpStatus.match('^[4]')) {
                /** error http status 4xx */
                errorMessage = error.error.message;
            } else if (httpStatus.match('^[5]')) {
                /** error http status 5xx */
                // errorMessage = ERROR_MESSAGES.STATUS_5XX;
                return error?.error.message;
            }
        }

        // ถ้ามี respHeader ให้ใช้ message จาก respHeader
        const respHeader: any = this.getRespHeader(error);
        if (respHeader.errorDesc && respHeader?.errorCode) {
            errorMessage = respHeader?.errorDesc + " (" + respHeader?.errorCode + ")";
        }
        return errorMessage;
    }

    private getHtmlReqID(reqId: any) {
        const styleReqId = `style="
            bottom:0; right:0;
            position: absolute;
            font-size: x-small;
            padding-right: 20px;
            opacity: .4;"`;
        const htmlReqID = `<p ${styleReqId}> Req ID : ${reqId}</p>`;
        const styleReqDateTime = `style="
            bottom:0; left:0;
            position: absolute;
            font-size: x-small;
            padding-left: 20px;
            opacity: .4;"`;
        const data = formatDate(new Date(), 'EEE MMM dd yyyy HH:mm:ss:S zzzz', 'en-Us')
        const htmlDateTime = `<p ${styleReqDateTime}> ${data}</p>`;
        return htmlDateTime + htmlReqID;
    }

    private getRespHeader(err: HttpErrorResponse) {
        try {
            const respheader = err.headers.get('respheader') as string;
            const decode = decodeURIComponent(escape(window.atob(respheader)?.replace(/\s/g, '')));
            return JSON.parse(decode);
        } catch (err) {
            return {};
        }
    }

    private tryJsonParseString(str: string): any {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    }

}

export enum FileType {
    PDF = "application/pdf",
    TEXT = "text/plain",
    GIF = "image/gif",
    JPEG = "image/jpeg",
    PNG = "image/png",
    DOCX = '.docx',
    DOCX_FULL = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface Options {
    value?: number | string;
    label?: string;
    labelEn?: string;
    type?: string;
    labelDesc?: string;
    status?: number;
    items?: Options[];
    totalRecords?: number;
    image?: string;
}
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenService } from './@core/services/auth.service';
import { AUTH_ROUTES, PROFILE_ROUTES, ROOT_ROUTES } from './@core/constants/routes.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authenService: AuthenService,
    private router: Router,
    // private route: ActivatedRoute,

  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      var profile = this.authenService.getProfile()
    //  console.log(this.authenService.getProfile());
      
      // console.log("this.route.snapshot", route.routeConfig);

      // this.route.snapshot.url.subscribe(urlSegments => {
      //   // Access and check the current route URL segments
      //   console.log(urlSegments);
      // });

      if (profile == null) {
        return true;
      } else {
        if (profile?.profile_status && profile?.email_status && profile.citizen_status) {
          return true;
        } else {
          if (route.routeConfig.path != "profile") {
            sessionStorage.clear();
            localStorage.clear();
            this.router.navigate(['/', ROOT_ROUTES.auth, AUTH_ROUTES.login]);
            
          }
        //  console.log("nav");
          
          return true;
        }

      }
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //  console.log("childRoute", childRoute);

      var profile = this.authenService.getProfile()
      
      if (profile?.profile_status && profile?.email_status && profile.citizen_status) {
        return true;
      } else {
        if (childRoute.routeConfig.path != "confirm") {
          // this.router.navigate(['/', ROOT_ROUTES.profile, PROFILE_ROUTES.confirm]);
          
        }
      //  console.log("nav");
        
        return true;
      }
    //  console.log("nav");
      
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROOT_ROUTES } from './@core/constants/routes.constant';
import { LayoutPublicComponent } from './@layout/layout-component/layout-public/layout-public.component';
import { AuthGuard } from './auth.guard';

const routesPublic: Routes = [
  {
    path: ROOT_ROUTES.auth,
    // canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: ROOT_ROUTES.buyer,
    canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./buyer/buyer.module').then(m => m.BuyerModule)
  },
  {
    path: ROOT_ROUTES.inquiry,
    canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./inquiry/inquiry.module').then(m => m.InquiryModule)
  },
  {
    path: ROOT_ROUTES.saller,
    canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./saller/saller.module').then(m => m.SallerModule)
  },

  {
    path: ROOT_ROUTES.profile,
    // canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },

  {
    path: ROOT_ROUTES.detail,
    canActivate: [AuthGuard],
    component: LayoutPublicComponent,
    loadChildren: () => import('./detail/detail.module').then(m => m.DetailModule)
  },
];

const redirectTo = ROOT_ROUTES.public;

let routes: Routes = [
  { path: '', redirectTo: redirectTo, pathMatch: 'full' },
  // { path: 'intro-page', component: IntroPageComponent },
];

routes = routes.concat(routesPublic);


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

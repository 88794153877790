
export class WhitelistErrors {
    static ErrorCode: string[] = [
        /** Service getLawsByIndex แล้วไม่ได้ข้อมูล*/
        'LII_DATA_NOTFOUND',
        /** Service getPublicNews ไม่พบข้อมูลบนระบบ กรุณาทำรายการอีกครั้ง */
        'PHN_DATA_NOTFOUND',
        'PHF_DATA_NOTFOUND',
        /** Service getProcs ไม่พบข้อมูลบนระบบ */
        'GPS_DATA_NOTFOUND',
        'PGP_DATA_NOTFOUND'
    ];

    static ErrorCodeRedirect: string[] = [
        /** ใส่ otp ไม่ถูกต้องเกิน 3 ครั้ง*/
        'AVO_OTP_EXCEED_LIMIT',
        /** ขอ otp เกิน 3 ครั้ง*/
        'ARO_OTP_EXCEED_REQUEST',
    ];

    static ErrorCodeOtp: string[] = [
        /** otp หมดอายุ */
        'AVO_OTP_EXPIRED'
    ]

    static ServiceName: string[] = [
    ];
}

export class WhitelistRetry {

    static ServiceName: string[] = [
        /** ใช้ตอน uploadFile */
    ];

    static ExceptErrorCode: string[] = [
        /** มีขนาดเกิน */
        'FUF_FILE_TOO_LARGE',
    ];

}

export const ROOT_ROUTES = {
    public: 'public',
    auth: 'auth',
    buyer: 'buyer',
    saller: 'saller',
    profile: 'profile',
    detail: 'detail',
    inquiry: 'inquiry',
    course: 'course'
}


export const PUBLIC_ROUTES = {
    home: 'home',
    course: 'course',
    courseNew: 'course-new',
    coursePopular: 'course-popular',
    courseAll: 'course-all',
    contactUs: 'contact-us',
    aboutUs: 'about-us',
    blog: 'blog',
    faq: 'faq',
    vendor: 'vendor',
    how: 'how',
};

export const BUYER_ROUTES = {
    form: 'form',
    edit: 'edit',
    inquiry: 'inquiry'
};

export const INQUIRY_ROUTES = {
    form: 'form',
    edit: 'edit',
};

export const SALLER_ROUTES = {
    form: 'form',
    edit: 'edit'
};

export const PROFILE_ROUTES = {
    page: 'page',
    chat: 'chat',
    update: 'update',
    confirm: 'confirm',
};

export const DETAIL_ROUTES = {
    page: 'page',
    inquery: 'inquery',
    search: 'search',
    member: 'member',
    match_asset: 'match_asset',
    view_asset: 'view_asset'
};

export const COURSE_ROUTES = {
    form: 'form',
    edit: 'edit',
    view: 'view',
};

export const PUBLIC_ABOUT_US = {
    review: 'review'
}

export const AUTH_ROUTES = {
    person: 'sign-up-person',
    agent: 'sign-up-agent',
    login: 'login',
    sign_up: 'sign-up',
    forgot: 'forgot',
    bypass: 'bypass',
}

import { PaginationRequest, PaginationResponse } from "../common/paginations"

export class PaginationConstant {
    static DefaultResponsePagination: PaginationResponse = {
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalRecords: 0
    }

    static DefaultRequestPagination: PaginationRequest = {
        currentPage: 1,
        pageSize: 10
    }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Carousel } from 'primeng/carousel';
import { TranslateUtil } from './@core/utils/translate.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'billionb';

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
    TranslateUtil.setTranslationService(this.translate);
  }

  ngOnInit() {
  //   this.router.events.subscribe((evt) => {
  //     if (!(evt instanceof NavigationEnd)) {
  //         return;
  //     }
  //     window.scrollTo(0, 0)
  // });
  Carousel.prototype.onTouchMove = () => { };
  }

  ngOnDestroy() {}
}

export const PUBLIC_URL = {
    contact_us: "contact_us",
    review: "review",
    blog: "api",
    home: "home",
    download: "download",
    course: "course",
    api: "api"
}
export const ROOT_URL = {
    authen: 'api',
    api: 'api',
    auth: 'auth',
}

export const AUTH_URL = {
    login: 'login',
    reAuthen: 'check_login',
    logout: 'logout',
}

export const FORM_URL = {
    get_property_location_nearby: 'get_property_location_nearby',
    get_inquiry_type: 'get_inquiry_type',
    get_property_type: 'get_property_type',
    get_property_period: 'get_property_period',
    get_province: 'get_province',
    get_district: 'get_district',
    get_sub_district: 'get_sub_district',

    get_form: 'get_form',

    asset: 'asset',
    get_asset_by_id: 'get_asset_by_id',
    change_status_asset: 'change_status_asset',
    finish_asset: 'finish_asset',

    inquiry: 'inquiry',
    get_inquiry_by_id: 'get_inquiry_by_id',
    change_status_inquery: 'change_status_inquiry',
    finish_inquery: 'finish_inquiry',

    get_property_sub_facility: 'get_property_sub_facility',
    property_location_nearby_page: 'property_location_nearby_page',
    get_property_tag: 'get_property_tag'
}

export const UPLOAD_IMAGES = {
    upload_images: 'upload_images',
    upload_file: 'upload_file',
    upload_images_fn: 'upload_images_fn',
    upload_file_fn: 'upload_file_fn',
}

export const CONTACT_US_URL = {
    save: "add_post_contact"
}


export const REVIEW = {
    list: "list",
    getRating: "getRating",
    member_comment_page: 'member_comment_page',
    member_comment: 'member_comment',
    app_comment_page: 'app_comment_page',
    get_app_comment: 'get_app_comment',
    app_comment: 'app_comment'
}

export const BLOG = {
    news_page: "news_page",
    get_news_type: "get_news_type",
    get_news_by_id: 'get_news_by_id',
    get_news_comment: 'get_news_comment',
    get_news_comment_reply: 'get_news_comment_reply',
    news_comment: 'news_comment',
    news_comment_reply: 'news_comment_reply',
    get_news_tags: "news_tag_page",
    course_comment: "course_comment",
    get_course_comment: "get_course_comment",

}

export const HOME = {
    getIconProfile: "get_property_type_icon",
    getHomeDetail: "get_summary_dashboard",
    getHomeBanner: "getHomeBanner",
    searchNewDemandPurchase: "searchNewDemandPurchase",
    getBannerNewProject: "getBannerNewProject",

    searchDemandPurchase: "searchDemandPurchase",

    searchDemandRent: "searchDemandRent",
    getBannerRent: "getBannerRent",

    searchSaleMortgage: "searchSaleMortgage",
    
    searchPurchaseMortgage: "searchPurchaseMortgage",
    getBannerPurchaseMortgage: "getBannerPurchaseMortgage",
    
    getInqueryPage: "inquiry_page",
    get_property_color_land: "get_property_color_land",
    
    getAssetPage: "asset_page",

    count_view_vendor: "count_view_vendor",
    
    asset_matching_page: "asset_matching_page",
    asset_matching_location_nearby_page: "asset_matching_location_nearby_page",
    
    asset_favorite_page: "asset_favorite_page",
    asset_favorite: "asset_favorite",
    inquiry_favorite_page: "inquiry_favorite_page",
    inquiry_favorite: "inquiry_favorite",
    get_dashboard_asset_by_member: 'get_dashboard_asset_by_member',
    inquiry_matching_page: 'inquiry_matching_page',
    inquiry_random_page: 'inquiry_random_page',
    asset_meeting_date_page: 'asset_meeting_date_page',

    present_asset: 'present_asset_to_inquiry',
    get_faq: 'get_faq',
    get_vendor: 'get_vendor',
    get_vendor_company: 'get_vendor_company',
    get_banner: 'get_banner',
    get_manual: 'get_manual',
    get_banner_footer: 'get_banner_footer',
}

export const MEMBER_URL = {
    get_member_group: 'get_member_group',
    register_member: 'register_member',
    forgot_password_member: 'forgot_password_member',
    login_member: 'login_member',
    member: 'member',
    confirm_citizen_member: 'confirm_citizen_member',
    confirm_email_member: 'confirm_email_member',
    confirm_otp_email_member: 'confirm_otp_email_member',
    add_infomation_member: 'add_infomation_member',
    update_profile_member: 'update_profile_member',
    get_permission_post_member: 'get_permission_post_member',
    reset_password_member: 'reset_password_member',

    get_member_comment: 'get_member_comment',

    login_google: 'google',
    login_facebook: 'facebook',
    login_social_member: 'login_social_member',
    update_member_group: 'update_member_group',
    get_focat_asset_member: 'get_focat_asset_member',
    asset_dashboard_page: 'asset_dashboard_page',
    get_notification_member: 'get_notification_member',
    read_notification_member: 'read_notification_member'
}

export const INQUERY_URL = {
    get_inquiry_by_id: 'get_inquiry_by_id',
}

export const CONFIG = {
    config: 'get_config_by_id',
}

export const ASSET_URL = {
    asset: 'asset',
    get_asset_by_id: 'get_asset_by_id'
}

export const COURSE = {
    course_reward_page: "course_reward_page",
    course_lesson_page: "course_lesson_page",
    course_page: "course_page",
    list: "list-course",
    filter: "filter-course",
    get_course_by_id:'get_course_by_id',
    payment_course: "payment_course",
}

export const MEMBER_POINT = {
    get_member_point_history: 'member_point_page',
    get_member_point_redeem: 'gift_voucher_page',
    get_member_point_available: 'gift_voucher_code_member_page',
    get_member_point_used: 'get_member_point_used',
    get_member_point_expired: 'get_member_point_expired',
    gift_voucher_code_member: 'gift_voucher_code_member',
    gift_voucher_code: 'gift_voucher_code'
}

export const ASSET_CHAT = {
    asset_chat: 'asset_chat',
    asset_chat_msg_page: 'asset_chat_msg_page',
    asset_chat_msg: 'asset_chat_msg',
    asset_chat_page: 'asset_chat_page',
    asset_meeting_date: 'asset_meeting_date',
    update_status_asset_meeting_date: 'update_status_asset_meeting_date',

    close_deal_asset_chat: 'close_deal_asset_chat',
    metting_asset_chat: 'metting_asset_chat'
}

export const REPORT = {
    get_report_title: 'get_report_title',
    asset_report: 'asset_report',
    inquiry_report: 'inquiry_report',
    member_report: 'member_report'
}

export const BANNER = {
    get_banner_pomotion: 'get_banner_pomotion',
    payment_banner_pomotion: 'payment_banner_pomotion',
    transection: 'transection',
    add_banner: 'add_banner',
    get_banner: 'get_banner',
    get_course_tag: 'get_course_tag'

}

export const AGENT = {
    get_co_agent: 'get_co_agent',
    recrived_co_agent: 'recrived_co_agent',
    co_agent_member: 'co_agent_member',
    get_co_agent_member: 'get_co_agent_member',
    get_co_agent_process: 'get_co_agent_process',
    co_agent_member_log: 'co_agent_member_log',
    upload_images_fn: 'upload_images_fn',
    upload_file_fn: 'upload_file_fn',
    get_co_agent_member_log: 'get_co_agent_member_log',
    

}

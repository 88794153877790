<div class="layout-top-menubar position-relative" style="max-height: 100% !important; padding: 0;">
  <div class="relative">
    <!-- {{banner_main | json}} -->
    <video *ngIf="bg_banner" controls autoplay loop muted class="img-content-pic bg-pic d-md-none d-block" width="100%"
      height="100%">
      <source src="https://billionb.dev-asha.com/files/banner_main/1701319532.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- <div class="absolute top-0 left-0 w-100 h-100" style="background-color: red;">

    </div> -->
    <!-- <img *ngIf="bg_banner" class="img-content-pic bg-pic d-md-none d-block" src={{banner_main[0]}} /> -->
  </div>

  <div class="" style="padding: 20px">
    <div
      class="layout-top-menubar-container justify-content-between flex-column flex-xl-row flex-wrap flex-xl-nowrap gap-2"
      [ngClass]="{ 'padding-content': true }">


      <div class="d-flex justify-content-center align-items-center logo-img">
        <a [routerLink]="['/']" [queryParams]="{ '/': '' }" class="d-flex">
          <img alt="OCS" class="my-auto logo-image" [src]="'assets/images/logo-billionb.png'" />
        </a>
      </div>
      <!-- <div class="logo d-xl-none">
        <a [routerLink]="['/']" [queryParams]="{ '/': '' }" class="d-flex">
          <img alt="OCS" class="my-auto logo-image" [src]="'assets/images/logo-billionb.png'" />
        </a>
      </div> -->

      <div class="d-flex flex-row flex-nowrap gap-3 align-items-center">
        <div class="top-menu d-none d-xl-flex align-items-center justify-content-start" style="flex-grow: 1;">
          <ng-container *ngTemplateOutlet="menubar"></ng-container>
        </div>
        <div class="top-menu d-none d-xl-flex align-items-center justify-content-end">
          <div class="config-menu">
            <ng-container *ngTemplateOutlet="menuConfig"></ng-container>
          </div>
        </div>

      </div>

      <!-- <div class="d-lg-none">
        <i
          class="fa fa-bars pointer p-2"
          (click)="isDisplayMainDialog = true"
        ></i>
      </div> -->
    </div>
    <!-- <br /> -->
    <div class="">
      <div class="d-flex d-xl-none flex-row p-2 align-items-start justify-content-between">
        <div class="button-menu" style="display: inline;">
          <a class="menu-button d-flex">
            <i *ngIf="bg_banner" class="pi pi-bars d-md-none d-block" style="font-size: 20px;"
              (click)="opendialog()"></i>
            <i *ngIf="bg_banner" class="pi pi-bars d-xl-none d-md-block d-none"
              style="font-size: 20px; padding: 5px; color: black;" (click)="opendialog()"></i>
            <i *ngIf="!bg_banner" class="pi pi-bars" style="font-size: 20px; padding: 5px; color: black;"
              (click)="opendialog()"></i>
          </a>

          <!-- <div class="right-menu">
            <button pButton pRipple type="button" icon="pi pi-search" label="ค้นหาความต้องการซื้อ" class="search-btn p-button-secondary"
              (click)="searchInquiryAdvance()"></button>
          
          
            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" (onChange)="changeLang($event)" optionLabel="name"
              class="flag">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="selectedCountry">
                  <img src="assets/images/{{ selectedCountry.value }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <img src="assets/images/{{ country.value.toLowerCase() }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
            </p-dropdown>
            <ng-container *ngIf="IsLogin">
              <button pButton pRipple type="button" icon="pi pi-user" class="p-button-raised p-button-text p-button-plain me-2"
                (click)="menu.toggle($event)" id="user-top-menu-bar" name="user-top-menu-bar"></button>
              <p-slideMenu #menu [popup]="true" [model]="items" [menuWidth]="200" [viewportHeight]="200"></p-slideMenu>
          
              <p-button class="badge-topbar" icon="pi pi-bell" styleClass="p-button-raised p-button-text p-button-plain"
                [badge]="badge" badgeClass="p-badge-danger" (click)="badge>0? noti.toggle($event): ''"></p-button>
          
              <p-slideMenu #noti [popup]="true" [model]="notiItems" [menuWidth]="300" [viewportHeight]="300"
                class="noti"></p-slideMenu>
            </ng-container>
          
            <ng-container *ngIf="!IsLogin">
              <button pButton pRipple type="button" label="{{ 'เข้าสู่ระบบ' | translate }}"
                class="search-btn p-button-secondary" (click)="goTo('/auth/login')"></button>
            </ng-container>
  
          </div> -->

        </div>
        <div class="w-100 d-flex flex-row gap-2 justify-content-end align-items-center">
          <div class="sec1 w-100 d-flex flex-column gap-2 justify-content-center align-items-center">
            <div class="w-100 d-flex justify-content-end">
              <button pButton pRipple type="button" icon="pi pi-search" label="{{'ค้นหาความต้องการซื้อ' | translate}}"
                style="width: 100%;" class="search-btn p-button-secondary d-none d-sm-flex gap-2 top-menu-button"
                (click)="searchInquiryAdvance()"></button>
              <button pButton pRipple type="button" icon="pi pi-search" label="{{'ค้นหา' | translate}}"
                style="width: 100%;" class="search-btn p-button-secondary d-sm-none d-flex gap-2 top-menu-button"
                (click)="searchInquiryAdvance()"></button>
            </div>
            <div *ngIf="!Display" class="w-100 button1">
              <div class="w-100 want-button">
                <div class="d-flex flex-row flex-wrap gap-2 justify-content-end w-100">
                  <ng-container *ngIf="isDisplay1">
                    <button pButton pRipple type="button" label="{{ labelBtn1 | translate }}" icon="pi pi-plus"
                      style="width: auto; padding: auto 0px;" class="defalut-btn p-button-secondary icon-text-btn top-menu-button"
                      (click)="create()"></button>
                  </ng-container>
                  <ng-container *ngIf="isDisplay">
                    <button pButton pRipple type="button" label="{{ labelBtn | translate }}" icon="pi pi-plus"
                      style="width: auto; padding: auto 0px;" class="defalut-btn p-button-secondary icon-text-btn top-menu-button"
                      (click)="create()"></button>
                  </ng-container>

                </div>

                <!-- <ng-container *ngIf="isDisplay2">
                  <button pButton pRipple type="button" label="{{ 'เปลี่ยนสถานะ' | translate }}" icon="pi pi-plus"
                    style="width: auto;" class="defalut-btn p-button-secondary icon-text-btn "
                    (click)="changestatus = true"></button>
                </ng-container> -->
              </div>


            </div>
          </div>
          <div *ngIf="!Display" class="sec2 gap-1 d-flex flex-row-reverse flex-wrap">
            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" (onChange)="changeLang($event)"
              optionLabel="name" class="flag">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="selectedCountry">
                  <img src="assets/images/{{ selectedCountry.value }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <img src="assets/images/{{ country.value.toLowerCase() }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
            </p-dropdown>
            <div class="d-flex flex-row gap-2 justify-content-between top-menu-button">
              <ng-container *ngIf="IsLogin">
                <div class="bg-white">
                  <button pButton pRipple type="button" icon="pi pi-user"
                    class="p-button-raised p-button-text p-button-plain " (click)="menu.toggle($event)"
                    id="user-top-menu-bar" name="user-top-menu-bar"></button>
                  <p-slideMenu #menu [popup]="true" [model]="items" [menuWidth]="200"
                    [viewportHeight]="200"></p-slideMenu>

                </div>
                <div class="bg-white">
                  <p-button class="bg-white" class="badge-topbar" icon="pi pi-bell"
                    styleClass="p-button-raised p-button-text p-button-plain" [badge]="badge"
                    badgeClass="p-badge-danger" (click)="badge>0? noti.toggle($event): ''"></p-button>

                </div>

                <!-- <p-slideMenu #noti [popup]="true" [model]="notiItems" [menuWidth]="300" [viewportHeight]="300"
                    class="noti"></p-slideMenu> -->
              </ng-container>

              <ng-container *ngIf="!IsLogin">
                <button pButton pRipple type="button" label="{{ 'เข้าสู่ระบบ' | translate }}" style="min-width: 100px;"
                  class="search-btn p-button-secondary" (click)="goTo('/auth/login')"></button>
              </ng-container>

            </div>
          </div>
          <div *ngIf="Display" class="sec2 gap-1 d-flex flex-row-reverse">
            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" (onChange)="changeLang($event)"
              optionLabel="name" class="flag">
              <ng-template pTemplate="selectedItem">
                <div class="country-item country-item-value" *ngIf="selectedCountry">
                  <img src="assets/images/{{ selectedCountry.value }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <img src="assets/images/{{ country.value.toLowerCase() }}-flag.png" [class]="'flag'" />
                </div>
              </ng-template>
            </p-dropdown>
            <div class="d-flex flex-row gap-2 justify-content-between top-menu-button">
              <ng-container *ngIf="IsLogin">
                <div class="bg-white">
                  <button pButton pRipple type="button" icon="pi pi-user"
                    class="p-button-raised p-button-text p-button-plain " (click)="menu.toggle($event)"
                    id="user-top-menu-bar" name="user-top-menu-bar"></button>
                  <p-slideMenu #menu [popup]="true" [model]="items" [menuWidth]="200"
                    [viewportHeight]="200"></p-slideMenu>

                </div>
                <div class="bg-white">
                  <p-button class="bg-white" class="badge-topbar" icon="pi pi-bell"
                    styleClass="p-button-raised p-button-text p-button-plain" [badge]="badge"
                    badgeClass="p-badge-danger" (click)="badge>0? noti.toggle($event): ''"></p-button>

                </div>

                <!-- <p-slideMenu #noti [popup]="true" [model]="notiItems" [menuWidth]="300" [viewportHeight]="300"
                    class="noti"></p-slideMenu> -->
              </ng-container>

              <ng-container *ngIf="!IsLogin">
                <button pButton pRipple type="button" label="{{ 'เข้าสู่ระบบ' | translate }}" style="min-width: 100px;"
                  class="search-btn p-button-secondary" (click)="goTo('/auth/login')"></button>
              </ng-container>

            </div>
          </div>
        </div>

      </div>



    </div>

  </div>
</div>

<ng-template #menubar>
  <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'home' }"
    (click)="goToHome()">
    {{ "หน้าแรก" | translate }}
  </a>
  <!-- <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'how' }"
    (click)="goYoutube()" href="/#videoYoutube">
    {{ "วิธีการใช้งาน" | translate }}
  </a> -->
  <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'blog' }"
    (click)="goTo('/blog')">
    {{ "ข่าวสาร" | translate }}
  </a>
  <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'about-us' }"
    (click)="goTo('/about-us')">
    {{ "เกี่ยวกับเรา" | translate }}
  </a>
  <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'contact-us' }"
    (click)="goTo('/contact-us')">
    {{ "ติดต่อเรา" | translate }}
  </a>
  <!-- <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'vendor' }"
    (click)="goTo('/vendor')">
    {{ "พันธมิตร" | translate }}
  </a> -->
  <!-- <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'faq' }"
    (click)="goTo('/faq')">
    {{ "FAQs" | translate }}
  </a> -->
  <!-- <a class="topbar-menu pointer topbar-text" [ngClass]="{ 'topbar-menu-active': activeMenu == 'course' }"
    (click)="goTo('/course')">
    {{ "คอร์สอสังหา" | translate }}
  </a> -->
</ng-template>


<ng-template #menuConfig>
  <div class="button2" style="display: inline;">
    <button pButton pRipple type="button" icon="pi pi-search" label="{{'ค้นหาความต้องการซื้อ' | translate}}"
      class="button-search search-btn p-button-secondary d-none d-md-flex gap-2" (click)="searchInquiryAdvance()">
    </button>
    <button pButton pRipple type="button" icon="pi pi-search" label="{{'ค้นหา' | translate}}"
      class="button-search search-btn p-button-secondary d-md-none d-flex gap-2" (click)="searchInquiryAdvance()">
    </button>

    <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" (onChange)="changeLang($event)" optionLabel="name"
      class="flag">
      <ng-template pTemplate="selectedItem">
        <div class="country-item country-item-value" *ngIf="selectedCountry">
          <img src="assets/images/{{ selectedCountry.value }}-flag.png" [class]="'flag'" />
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <img src="assets/images/{{ country.value.toLowerCase() }}-flag.png" [class]="'flag'" />
        </div>
      </ng-template>
    </p-dropdown>
    <ng-container *ngIf="IsLogin">
      <button pButton pRipple type="button" icon="pi pi-user" class="p-button-raised p-button-text p-button-plain"
        (click)="menu.toggle($event)" id="user-top-menu-bar" name="user-top-menu-bar"></button>
      <p-slideMenu #menu [popup]="true" [model]="items" [menuWidth]="200" [viewportHeight]="200"></p-slideMenu>

      <p-button class="badge-topbar" icon="pi pi-bell" styleClass="p-button-raised p-button-text p-button-plain"
        [badge]="badge" badgeClass="p-badge-danger" (click)="badge>0? noti.toggle($event): ''"></p-button>

      <p-slideMenu #noti [popup]="true" [model]="notiItems" [menuWidth]="300" [viewportHeight]="300"
        class="noti"></p-slideMenu>
    </ng-container>

    <ng-container *ngIf="!IsLogin">
      <button pButton pRipple type="button" label="{{ 'เข้าสู่ระบบ' | translate }}"
        class="search-btn p-button-secondary" style="min-width: 85px;" (click)="goTo('/auth/login')"></button>
    </ng-container>

  </div>
  <div *ngIf="!Display" class="button1" style="display: inline; padding-bottom: 5px;">
    <!-- <div class="d-flex flex-col flex-grow-1 gap-2">
      
    </div> -->
    <ng-container *ngIf="isDisplay1">
      <button pButton pRipple type="button" label="{{ labelBtn1 | translate }}" icon="pi pi-plus"
        style="width: auto;max-width: fit-content;" class="defalut-btn p-button-secondary icon-text-btn"
        (click)="create(2)"></button>
    </ng-container>
    <ng-container *ngIf="isDisplay">
      <button pButton pRipple type="button" label="{{ labelBtn | translate }}" icon="pi pi-plus"
        style="width: auto;max-width: fit-content;" class="defalut-btn p-button-secondary icon-text-btn"
        (click)="create(1)"></button>
    </ng-container>
    <!-- <ng-container *ngIf="isDisplay2">
      <button pButton pRipple type="button" label="{{ 'เปลี่ยนสถานะ' | translate }}" icon="pi pi-plus"
        style="width: auto;max-width: fit-content;" class="defalut-btn p-button-secondary icon-text-btn"
        (click)="changestatus = true"></button>
    </ng-container> -->

  </div>

  <!-- <ng-container *ngIf=" labelBtn">
    <button pButton pRipple type="button" label="{{ labelBtn | translate }}" icon="pi pi-plus"
      class="defalut-btn me-2 p-button-secondary icon-text-btn" (click)="create()"></button>
  </ng-container>
  <ng-container *ngIf=" labelBtn">
    <button pButton pRipple type="button" label="{{ 'เปลี่ยนสถานะ' | translate }}" icon="pi pi-plus"
      class="defalut-btn me-2 p-button-secondary icon-text-btn" (click)="changestatus = true"></button>
  </ng-container> -->

</ng-template>

<p-dialog [(visible)]="isDisplayMainDialog" class="custom-fullscreen-dialog" [modal]="true"
  [contentStyle]="{ height: '100vh' }">
  <div class="layout-dynamic-sidebar">
    <!-- <div class="slide-in-left"> -->
    <!-- <div class="config-menu">
      <ng-container *ngTemplateOutlet="menuConfig"></ng-container>
    </div> -->
    <div class="layout-menu">
      <div class="menu-items">
        <ng-container *ngTemplateOutlet="menubar"></ng-container>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog class="condition-dialog" header="{{'ข้อตกลงและเงื่อนไข' | translate }}" [(visible)]="isDisplayAddDemandBuyer"
  [style]="{width: '80vw'}">
  <div class="mb-3">
    <h5>{{'ข้อตกลง' | translate }}</h5>
  </div>
  <div class="mb-3" *ngIf="condition" [innerHTML]="condition | safeHtml"></div>
  <br>
  <p-checkbox [binary]="true" [(ngModel)]="confirmCheckBox"></p-checkbox>
  <label class="ms-2">{{ member| translate }}</label>
  <br>
  <ng-template pTemplate="footer">
    <p-button (click)="isDisplayAddDemandBuyer=false" label="{{'ยกเลิก' | translate }}"
      styleClass="p-button-text"></p-button>
    <p-button (click)="isDisplayAddDemandBuyer=false" (click)="addDamand()" [disabled]="!confirmCheckBox"
      label="{{'ยืนยัน' | translate }}" styleClass="search-btn me-2 p-button-secondary"></p-button>
    <br>
    <br>
  </ng-template>
</p-dialog>

<p-dialog header="{{'เปลี่ยนสถานะ' | translate }}" [(visible)]="changestatus" [styleClass]="'custom-dialog'"
  [breakpoints]="{'960px':'80vw'}">
  <form [formGroup]="form">
    <div class="p-fluid">
      <div class="grid changestatus" style="
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;">
        <div class="field col">
          {{"กลุ่มผู้ใช้" | translate}}
          <p-dropdown class="mb-3" formControlName="member_group_id" [options]="memberGroup" optionValue="id"
            optionLabel="name" (onClick)="getMemberGroup()" (onChange)="change()" [virtualScroll]="true">
            <!-- style="width: 48%; margin: bottom 5px;" -->
          </p-dropdown>
        </div>
        <div class="field col">
          {{"ประเภทสมาชิก" | translate}}
          <p-dropdown [options]="buyer_type" formControlName="buyer_type" optionValue="name_en" optionLabel="name">
            <!-- style="width: 48%;" -->
          </p-dropdown>
        </div>

      </div>
    </div>
  </form>
  <br>
  <ng-template pTemplate="footer">
    <p-button (click)="isDisplayAddDemandBuyer=false" label="{{'ยกเลิก' | translate }}"
      styleClass="p-button-text"></p-button>
    <p-button (click)="isDisplayAddDemandBuyer=false" (click)="submit()" [disabled]="form.value.buyer_type == ''"
      label="{{'ยืนยัน' | translate }}" styleClass="search-btn me-2 p-button-secondary"></p-button>
    <br>
    <br>
  </ng-template>

</p-dialog>
<router-outlet></router-outlet>

<ngx-spinner></ngx-spinner>
<p-confirmDialog></p-confirmDialog>

<p-scrollTop [threshold]="50" target="window"></p-scrollTop>

<div id="report-popup" style="
        display: flex;
        position: fixed;
        bottom: 1%;
        right: 1%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #97e163;
        /* padding: 7px; */
        border-radius: 100%;
        /* transform: translate(-100%, -100%); */
        width: 80px;
        height: 80px;
        z-index: 2000;
        scale: 1;">
    <!-- <label style="
        font-weight: 700;
        color: black;
        font-size: 10px;" 
    for="">ติดต่อเจ้าหน้าที่</label> -->
    <a href="https://page.line.me/billionbproperty" style="
    background-color: white;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
" >
    <img style="
        max-height: 45px;
        /* margin: 5px;         */
       " 
        
    src="assets/images/logo.png"  alt="">
    </a><!-- <label style="
        position: absolute;
        top: -20px;
        color: #9a9a9a;
        cursor: pointer;
        width: auto;
        text-align: center;" 
    href="#" id="close-btn" onclick="document.getElementById('report-popup').style.display = 'none'">x close x</label> -->
</div>

<div class="sub">
  <div class="row">
    <div class="" style="text-align: center;width: 41.66666667%;">
      <img class="image-sub" alt="OCS" [src]="image" />
    </div>
    <div class="col-7 align-items-center text-sub">
      <div class="row">
        <span style="font-size: 3vw">{{ title | translate }}</span>
        <span style="font-size: 1.3vw">{{ detail | translate }}</span>
      </div>
      <div class="" style="height: 2vw"></div>
      <div class="row">
        <div class="d-flex align-items-center">
          <!-- <input type="text" pInputText class="sub-input" placeholder=" {{'Enter your emaill address' | translate }} ..." [(ngModel)]="email"/> -->
          <button
            pButton
            pRipple
            type="button"
            label="{{ text_btn | translate }}"
            class="defalut-btn ms-4 p-button-secondary sub-btn"
            (click)="goCourse()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { RequestModel } from '../models/request-model';
import { ResponseModel } from '../models/response-model';
import { EncryptDecryptUtil } from '../utils/encrypt-decrypt.util';
import { NotificationUtil } from '../utils/notification.util';
import { AuthenService } from './auth.service';
import { ROOT_URL, UPLOAD_IMAGES } from '../constants/api-route.constant';

let checkStringData: boolean = true;
@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  currentRole: string = ''

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private auth: AuthenService
  ) { }

  postFile<T>(req: any, url?: string): Promise<T> {
    if(!url) url = `/${ROOT_URL.api}/${UPLOAD_IMAGES.upload_images}`
    return this.http.post(`${environment.API_ROOT_URL}` + url, req).toPromise()
    .then((resp: any) => {
      if(resp.data) {
        resp.respBody = resp.data;
      }
      if (resp.respBody) {
        return resp.respBody as T;
      }
      else {
        return {} as T;
      }
    })
      // .map(() => { return true; })
      // .catch((e) => this.handleError(e));
}
  postFileIMG<T>(req: any, url?: string): Promise<T> {
    if(!url) url = `/${ROOT_URL.api}/${UPLOAD_IMAGES.upload_images_fn}`
    return this.http.post(`${environment.API_ROOT_URL}` + url, req).toPromise()
    .then((resp: any) => {
      if(resp.data) {
        resp.respBody = resp.data;
      }
      if (resp.respBody) {
        return resp.respBody as T;
      }
      else {
        return {} as T;
      }
    })
      // .map(() => { return true; })
      // .catch((e) => this.handleError(e));
}
  postFiles<T>(req: any, url?: string): Promise<T> {
    if(!url) url = `/${ROOT_URL.api}/${UPLOAD_IMAGES.upload_file_fn}`
    return this.http.post(`${environment.API_ROOT_URL}` + url, req).toPromise()
    .then((resp: any) => {
      if(resp.data) {
        resp.respBody = resp.data;
      }
      if (resp.respBody) {
        return resp.respBody as T;
      }
      else {
        return {} as T;
      }
    })
      // .map(() => { return true; })
      // .catch((e) => this.handleError(e));
}


  post<T>(url: string, req: any, showSpinner: boolean = false): Promise<T> {
    checkStringData = true;
    if (req) {
      this.shiftData(req?.reqBody);
    }
    if (!checkStringData) {
      NotificationUtil.invalid('', 'ตรวจพบคำต้องห้าม');
      return throwError({}).toPromise();
    }
    if (showSpinner) {
      this.spinner.show();
    }
    return this.http.post<any>(`${environment.API_ROOT_URL}` + url, req).toPromise()
      .then(resp => {
        if(resp.token){
          this.auth.setToken(resp.token)
        }
        if(resp.data) {
          resp.respBody = resp.data;
        }
        if (resp.respBody) {
          return resp.respBody as T;
        }
        else {
          return {} as T;
        }
      })
      .finally(async () => {
        if (showSpinner) {
          this.spinner.hide();
        }
      });
  }

  post2<T>(url: string, req: any, showSpinner: boolean = false): Promise<T> {
    checkStringData = true;
    if (req) {
      this.shiftData(req?.reqBody);
    }
    if (!checkStringData) {
      NotificationUtil.invalid('', 'ตรวจพบคำต้องห้าม');
      return throwError({}).toPromise();
    }
    if (showSpinner) {
      this.spinner.show();
    }
    return this.http.post<any>(`${environment.API_ROOT_URL}` + url, req).toPromise()
      .then(resp => {
        if(resp.token){
          this.auth.setToken(resp.token)
        }
        if(resp) {
          resp.respBody = resp;
        }
        if (resp.respBody) {
          return resp.respBody as T;
        }
        else {
          return {} as T;
        }
      })
      .finally(async () => {
        if (showSpinner) {
          this.spinner.hide();
        }
      });
  }

  get<T>(url: string, req: any, showSpinner: boolean = false): Promise<T> {
    checkStringData = true;
    if (req) {
      this.shiftData(req?.reqBody);
    }
    if (!checkStringData) {
      NotificationUtil.invalid('', 'ตรวจพบคำต้องห้าม');
      return throwError({}).toPromise();
    }
    if (showSpinner) {
      this.spinner.show();
    }
    return this.http.get<any>(`${environment.API_ROOT_URL}` + url, req).toPromise()
      .then((resp: any) => {
        if(resp.data) {
          resp.respBody = resp.data;
        }
        if (resp.respBody) {
          return resp.respBody as T;
        }
      })
      .finally(async () => {
        if (showSpinner) {
          this.spinner.hide();
        }
      });
  }

  put<T>(url: string, req: any, showSpinner: boolean = false): Promise<T> {
    checkStringData = true;
    if (req) {
      this.shiftData(req?.reqBody);
    }
    if (!checkStringData) {
      NotificationUtil.invalid('', 'ตรวจพบคำต้องห้าม');
      return throwError({}).toPromise();
    }
    if (showSpinner) {
      this.spinner.show();
    }
    return this.http.put<any>(`${environment.API_ROOT_URL}` + url, req).toPromise()
      .then(resp => {
        if(resp.token){
          this.auth.setToken(resp.token)
        }
        if(resp.data) {
          resp.respBody = resp.data;
        }
        if (resp.respBody) {
          return resp.respBody as T;
        }
        else {
          return {} as T;
        }
      })
      .finally(async () => {
        if (showSpinner) {
          this.spinner.hide();
        }
      });
  }

  delete<T>(url: string, showSpinner: boolean = false): Promise<T> {
    return this.http.delete<any>(`${environment.API_ROOT_URL}` + url).toPromise()
      .then(resp => {
        return {} as T;
      })
      .finally(async () => {
        if (showSpinner) {
          this.spinner.hide();
        }
      });
  }

  getRole(): string {
    // let roleDecrypted = sessionStorage.getItem("role");
    // if (roleDecrypted) {
    //   roleDecrypted = EncryptDecryptUtil.decryptMassage(roleDecrypted)
    //   this.currentRole = EncryptDecryptUtil.decryptAES(roleDecrypted as string, CryptoKeyConstant.Role);
    // }
    return this.currentRole;
  }

  shiftData(obj: any) {
    let canRun = true;
    if (obj) Object.keys(obj)?.forEach(f => {
      if (canRun) {
        if (obj[f] != null) {
          if (this.checkObject(obj[f])) {
            this.shiftData(obj[f]);
          } else {
            if (obj[f].toString()) {
              canRun = this.checkScript(obj[f].toString());
            }
          }
        }
        if (!canRun) {
          console.warn("Forbidden word detected")
          checkStringData = false;
        }
      }
    });

  }

  checkObject(myObj: any) {
    return (typeof myObj === 'object') && (typeof myObj.getMonth !== 'function');
  }

  checkScript(myObj: any) {
    let stringErrorList = [];
    // let stringErrorList = WHITE_LIST_STRING.whitelist;
    if (stringErrorList.some(text => (myObj.includes(text)))) {
      return false;
    }
    else {
      return true;
    }
  }

}

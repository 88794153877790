import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import Swal from "sweetalert2";
import { AUTH_URL, CONFIG, MEMBER_URL, ROOT_URL } from "../constants/api-route.constant";
import { CryptoKeyConstant } from "../constants/crypto.constant";
import { PROFILE_ROUTES, PUBLIC_ROUTES, ROOT_ROUTES } from "../constants/routes.constant";
import { Login } from "../constants/service-name.constant";
import { LoginRequest } from "../models/login";
import { RequestModel } from "../models/request-model";
import { EncryptDecryptUtil } from "../utils/encrypt-decrypt.util";
import { RandomUtil } from "../utils/random.util";
import { TimeUtil } from "../utils/time.util";
import { AuthenService } from "./auth.service";
import { HttpClientService } from "./http-client.service";

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    isLogOut: boolean = false;
    configData: any;

    constructor(
        private httpClientService: HttpClientService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private auth: AuthenService
        // private confirmPopupService: ConfirmPopupService,
        // private apiDisplayConfigService: ApiDisplayConfigService,
    ) {

        /** ทำทุก 1 นาที */
        timer(0, 15 * 1000).pipe(
            mergeMap(async (_) => {
                let timeDecrtpted = sessionStorage.getItem('Time');
                if (timeDecrtpted) {
                    timeDecrtpted = EncryptDecryptUtil.decryptMassage(timeDecrtpted);
                    let time = EncryptDecryptUtil.decryptAES(timeDecrtpted as string, CryptoKeyConstant.Authen);
                    let logonDuration = Number(time) ? this.calculateDiffTime(Number(time)) : 0;
                    await TimeUtil.delay(200);
                    const idleTimeoutInMinutes = 240;
                    const uiAuthenInMinutes = 13;
                    if (
                        logonDuration >= uiAuthenInMinutes &&
                        logonDuration <= idleTimeoutInMinutes
                    ) {
                        this.reAuthen();
                    }
                }
            })
        ).subscribe();
    }

    login(data: LoginRequest): Promise<any> {
        let body = new RequestModel(Login.LOGIN, data);
        let url = `/${ROOT_URL.authen}/${MEMBER_URL.login_member}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
              // console.log("resp", resp)
                /* Set profile with AES In SessionStorage */
                let profileEncrypted = EncryptDecryptUtil.encryptAESObject(resp, CryptoKeyConstant.Authen);
                sessionStorage.setItem('profile', EncryptDecryptUtil.encryptMassage(profileEncrypted));
                this.getCondition();
                this.auth.setProfile(resp);
                sessionStorage.setItem('password', EncryptDecryptUtil.encryptMassage(data.password))
                
                if (resp.buyer_type === "person") {
                    const redirectTo = PUBLIC_ROUTES.course;
                    this.router.navigate(['/', redirectTo]);
                    
                } else {

                    if (resp.profile_status && resp.email_status && resp.citizen_status) {
                        const redirectTo = ROOT_ROUTES.profile;
                        // this.router.navigate(['/', redirectTo, 'page']);
                        this.router.navigate(['/', redirectTo, PROFILE_ROUTES.page]);
                        // this.router.navigate(['/', redirectTo, PROFILE_ROUTES.confirm]);

                      // console.log("move1");
                        
                        
                    } else {
                        const redirectTo = ROOT_ROUTES.profile;
                        // this.router.navigate(['/', redirectTo, 'page']);
                        this.router.navigate(['/', redirectTo, PROFILE_ROUTES.confirm]);
                        // this.router.navigate(['/', redirectTo, PROFILE_ROUTES.confirm]);
                        
                      // console.log("move2");
                    }

                }


                return resp
            });
    }
    loginPerson(data: LoginRequest): Promise<any> {
        let body = new RequestModel(Login.LOGIN, data);
        let url = `/${ROOT_URL.authen}/${MEMBER_URL.login_member}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                /* Set profile with AES In SessionStorage */
                let profileEncrypted = EncryptDecryptUtil.encryptAESObject(resp, CryptoKeyConstant.Authen);
                sessionStorage.setItem('profile', EncryptDecryptUtil.encryptMassage(profileEncrypted));
                this.getCondition();
                this.auth.setProfile(resp);
                sessionStorage.setItem('password', EncryptDecryptUtil.encryptMassage(data.password))
                const redirectTo = PUBLIC_ROUTES.course;
                this.router.navigate(['/', redirectTo]);
                return resp
            });
    }

    loginSocial(bodys: any) {
        let url = `/${ROOT_URL.authen}/${MEMBER_URL.login_social_member}`
        return this.httpClientService.post<any>(url, bodys, true)
            .then(resp => {
                /* Set profile with AES In SessionStorage */
                let profileEncrypted = EncryptDecryptUtil.encryptAESObject(resp, CryptoKeyConstant.Authen);
                sessionStorage.setItem('profile', EncryptDecryptUtil.encryptMassage(profileEncrypted));
                this.getCondition();
                this.auth.setProfile(resp);
                const redirectTo = ROOT_ROUTES.profile;
                this.router.navigate(['/', redirectTo, 'page']);
                return resp
            });
    }

    loginFacebook(): Promise<any> {
        let bodys = {};
        let url = `/${ROOT_URL.auth}/${MEMBER_URL.login_facebook}/redirect`
        return this.httpClientService.get<any>(url, bodys, true)
            .then(resp => {
                // console.log(resp)
                return resp
            });
    }



    async reAuthen() {
        let timeDecrtpted = sessionStorage.getItem('Time') as string;
        sessionStorage.removeItem('Time');
        /* Get role form local storage */
        let reAuthenToken = sessionStorage.getItem("reAuthenToken");
        if (reAuthenToken) {
            reAuthenToken = EncryptDecryptUtil.decryptMassage(reAuthenToken)
            reAuthenToken = EncryptDecryptUtil.decryptAES(reAuthenToken as string, CryptoKeyConstant.Authen);
            this.auth.setToken(reAuthenToken)
        }
        if (reAuthenToken) {
            let data = { reAuthenToken: reAuthenToken };
            let body = new RequestModel(Login.RESEND_AUTHEN, data);
            let url = `/${ROOT_URL.authen}/${AUTH_URL.reAuthen}`
            try {
                const resp = await this.httpClientService.post<any>(url, {});
                let profile = sessionStorage.getItem("profile");
                let mainProfile;
                if (profile) {
                    profile = EncryptDecryptUtil.decryptMassage(profile);
                    mainProfile = EncryptDecryptUtil.decryptAESObject(profile as string, CryptoKeyConstant.Authen);
                }
                await this.getCondition();
                this.auth.setProfile(mainProfile);
                this.setTimeToSessionStorage();
                return resp;
            } catch (err) {
                this.logout();
                sessionStorage.setItem('Time', timeDecrtpted);
            }
        }

    }

    removeSessionStorage() {
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('reAuthenToken');
        sessionStorage.removeItem('Time');
        sessionStorage.removeItem('sessionId');
        sessionStorage.removeItem('profile');
    }

    setTimeToSessionStorage() {
        /* Set Time with AES In SessionStorage */
        let currentDate = new Date();
        let encrypted = EncryptDecryptUtil.encryptAES(currentDate.getTime().toString(), CryptoKeyConstant.Authen);
        sessionStorage.setItem('Time', EncryptDecryptUtil.encryptMassage(encrypted));
    }

    async logout() {
            this.spinner.show();
            await TimeUtil.delay(200);
            // let body = new RequestModel(Login.LOGOUT);
            // let url = `/${ROOT_URL.authen}/${AUTH_URL.logout}`
            // this.httpClientService.post<any>(url, {}, true)
            //     .finally( () => {
            //         this.auth.setToken("");
            //         this.isLogOut = true;
            //         this.removeSessionStorage();
            //         this.spinner.hide();
            //         const redirectTo = ROOT_ROUTES.public;
            //         this.router.navigate(['/', redirectTo]);
            //     })
            this.auth.setToken("");
            this.isLogOut = true;
            this.removeSessionStorage();
            this.spinner.hide();
            this.router.navigate(['/']);

    }

    calculateDiffTime(time: number): number {
        let currentDate = new Date();
        return Math.abs(currentDate.getTime() - time) / (1000 * 60);
    }

    async getCondition(): Promise<any> {
            let data = {};
            let url = `/${ROOT_URL.api}/${CONFIG.config}/${1}`
            const resp = await this.httpClientService.get<any>(url, data);
            this.configData = resp;
            return resp;
    }
}
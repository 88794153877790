import * as uuid from 'uuid';

const THAI_CHARS = "กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ";

export const RandomUtil = {

    randomId(): string {
        return uuid.v4();
    },
    
    randomBoolean() {
        return Math.random() >= 0.5;
    },

    randomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    randomDate(): Date {
        return new Date(this.randomInt(1111111100000, 1111999900000));
    },

}
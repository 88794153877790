import { TranslateService } from "@ngx-translate/core";

export class TranslateUtil {
    static translation:TranslateService;

    constructor(private translate: TranslateService){
    }

    static setTranslationService( translation: TranslateService) {
        TranslateUtil.translation = translation;
    }

    static async translateText(input: string) {
        if(input && input.length > 0)
        return await TranslateUtil.translation.get(input).toPromise();
    }

    static getCurrentLang(): string {
        return this.translation.currentLang;
    }

    static isThaiLang(): boolean {
        return this.translation.currentLang == 'th';
    }
}

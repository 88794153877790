import { FORM_URL, HOME, ROOT_URL } from './../constants/api-route.constant';
import { Options } from './../constants/global.constant';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClientService } from "./http-client.service";
import { RequestModel } from '../models/request-model';
import { Home } from '../constants/service-name.constant';
import { PUBLIC_URL } from '../constants/api-route.constant';
import { DemandDataResp, DemandRequest, DemandResponce, SearchAssetListResp, SearchInqueryListReq, SearchInqueryListResp } from '../models/home-model';
import { PaginationUtil } from '../utils/paination.util';
import { PaginationResponse } from '../common/paginations';
import { RandomUtil } from '../utils/random.util';
import { ConverstNumberUtil } from '../utils/converst-number.util';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    isLogOut: boolean = false;
    
    listPropertyType: Options[] = [];
    listColorLand: Options[] = [];

    constructor(
        private httpClientService: HttpClientService,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) {
    }

    getHomeDetail(): Promise<Options[]> {
        this.spinner.show();
        let body = {};
        let url = `/${PUBLIC_URL.api}/${HOME.getHomeDetail}`
        return this.httpClientService.get<any>(url, body)
            .then(resp => {
                let data = [] as Options[];
                data = [
                    {
                        label: 'จำนวนทรัพย์ที่ต้องการซื้อ',
                        labelEn: 'NEED TO BUY',
                        value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.sum_inquiry)
                    },
                    {
                        label: 'จำนวนทรัพย์ที่ต้องการเช่า',
                        labelEn: 'NEED TO RENT',
                        value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.sum_asset_rent)
                    },
                    {
                        label: 'จำนวนผู้เข้าชมเว็ปไซต์',
                        labelEn: 'VISITOR',
                        value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.view)
                    },
                    {
                        label: 'มูลค่าความต้องการซื้อ',
                        labelEn: 'All of Requirement',
                        value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.all_of_total_price_inquiry)
                    },
                    // {
                    //     label: 'จำนวนทรัพย์ที่ต้องการขาย',
                    //     labelEn: 'FOR SALE',
                    //     value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.sum_asset)
                    // },
                    // {
                    //     label: 'มูลค่าทรัพย์ที่ขายอยู่ในระบบ',
                    //     labelEn: 'SALE VOLUME',
                    //     value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.sum_price_asset)
                    // },
                    // {
                    //     label: 'จำนวนทรัพย์ที่ต้องการให้เช่า',
                    //     labelEn: 'NEED TO RENT',
                    //     value: ConverstNumberUtil.convertToInternationalCurrencySystem(resp.sum_inquiry_rent)
                    // },
                ]
                return data;
            })
            // .catch(err => {
            //     let data = [] as Options[];
            //     data = [
            //         {
            //             label: 'จำนวนทรัพย์ที่ต้องการซื้อ',
            //             value: 12345
            //         },
            //         {
            //             label: 'จำนวนผู้เข้าชมเว็ปไซต์',
            //             value: 23432
            //         },
            //         {
            //             label: 'จำนวนทรัพย์ที่ต้องการเช่า',
            //             value: 678903
            //         },
            //         {
            //             label: 'จำนวนทรัพย์ที่ต้องการขาย',
            //             value: 8764354
            //         },
            //         {
            //             label: 'มูลค่าทรัพย์ที่ขายอยู่ในระบบ',
            //             value: 3456794
            //         },
            //         {
            //             label: 'จำนวนทรัพย์ที่ต้องการให้เช่า',
            //             value: 543922
            //         },
            //     ]
            //     return data;
            // })
            .finally(() => {
                this.spinner.hide();
            })
    }

    getHomeBanner(): Promise<string[]> {
        this.spinner.show();
        let body = new RequestModel(Home.GET_HOME_BANNER);
        let url = `/${PUBLIC_URL.home}/${HOME.getHomeBanner}`
        return this.httpClientService.post<string[]>(url, body)
            .then(resp => {
                return resp;
            })
            .catch(err => {
                let data = [] as string[];
                data = [
                    'https://billionb.dev-asha.com/images/asset/e3a92d004a6cec8f2b2a1017266bdd32.png',
                    'https://billionb.dev-asha.com/images/asset/9f7072ab51ac105de426a9a1421b90be.jpg',
                    'https://billionb.dev-asha.com/images/asset/366c2bd1de2587eff95c643f751b155c.jpg',
                    'https://billionb.dev-asha.com/images/asset/ecc2aee530f537eb10140b24719dee3c.jpg',
                    'https://billionb.dev-asha.com/images/asset/d5608335c82845a3746b932cbc398478.jpg',
                    'https://billionb.dev-asha.com/images/asset/2d93584b2d7c9cecfe04366c7b668d25.jpg',
                    'https://billionb.dev-asha.com/images/asset/964201c71ba128323f53a5b21003b1dd.jpg'
                    ]
                return data;
            })
            .finally(() => {
                this.spinner.hide();
            })
    }

    searchInquery(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.getInqueryPage}`
        return this.httpClientService.post<SearchInqueryListResp>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                        property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label
                    }
                })
                return resp;
            })
    }
    searchInqueryRandom(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.inquiry_random_page}`
        return this.httpClientService.post<SearchInqueryListResp>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                        property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label
                    }
                })
                return resp;
            })
    }

    searchAsset(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.getAssetPage}`
        return this.httpClientService.post<SearchAssetListResp>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                        property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label
                    }
                })
                return resp;
            })
    }


    searchNewDemandPurchase(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.getInqueryPage}`
        return this.httpClientService.post<SearchInqueryListResp>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp;
            })
            .catch(err => {
                // let resp = {} as DemandResponce;
                // let data = this.dataTest;
                // resp.data = data;
                // resp.pagination = {
                //     currentPage: 1,
                //     pageSize: 10,
                //     totalPages: 10,
                //     totalRecords: 99
                // } as PaginationResponse;
                // resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                // return resp
            });
    }

    getBannerNewProject(): Promise<string[]> {
        this.spinner.show();
        let body = new RequestModel(Home.GET_BANNER_NEW_PROJECT);
        let url = `/${PUBLIC_URL.home}/${HOME.getBannerNewProject}`
        return this.httpClientService.post<string[]>(url, body)
            .then(resp => {
                return resp;
            })
            .catch(err => {
                let data = [] as string[];
                data = [
                    'https://billionb.dev-asha.com/images/asset/88e499bbd8acab73f5a6d16dd85b5326.png',
                ]
                return data;
            })
            .finally(() => {
                this.spinner.hide();
            })
    }

    searchDemandPurchase(bodys: DemandRequest): Promise<any> {
        let body = new RequestModel(Home.SEARCH_DEMAND_PURCHASE, bodys);
        let url = `/${PUBLIC_URL.home}/${HOME.searchDemandPurchase}`
        return this.httpClientService.post<DemandResponce>(url, body)
            .then(resp => {
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp;
            })
            .catch(err => {
            });
    }

    searchDemandRent(bodys: DemandRequest): Promise<any> {
        let body = new RequestModel(Home.SEARCH_DEMAND_RENT, bodys);
        let url = `/${PUBLIC_URL.home}/${HOME.searchDemandRent}`
        return this.httpClientService.post<DemandResponce>(url, body)
            .then(resp => {
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp;
            })
            .catch(err => {
            });
    }

    getBannerRent(): Promise<string[]> {
        this.spinner.show();
        let body = new RequestModel(Home.GET_BANNER_RENT);
        let url = `/${PUBLIC_URL.home}/${HOME.getBannerRent}`
        return this.httpClientService.post<string[]>(url, body)
            .then(resp => {
                return resp;
            })
            .catch(err => {
                let data = [] as string[];
                data = [
                    'https://billionb.dev-asha.com/images/asset/7dbef7e309a57581175ca6e06f1e3289.png',
                ]
                return data;
            })
            .finally(() => {
                this.spinner.hide();
            })
    }

    searchSaleMortgage(bodys: DemandRequest): Promise<any> {
        let body = new RequestModel(Home.SEARCH_SALE_MORTGAGE, bodys);
        let url = `/${PUBLIC_URL.home}/${HOME.searchSaleMortgage}`
        return this.httpClientService.post<DemandResponce>(url, body)
            .then(resp => {
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp;
            })
            .catch(err => {
            });
    }

    searchPurchaseMortgage(bodys: DemandRequest): Promise<any> {
        let body = new RequestModel(Home.SEARCH_PURCHASE_MORTGAGE, bodys);
        let url = `/${PUBLIC_URL.home}/${HOME.searchPurchaseMortgage}`
        return this.httpClientService.post<DemandResponce>(url, body)
            .then(resp => {
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp;
            })
            .catch(err => {
            });
    }

    getBannerPurchaseMortgage(): Promise<string[]> {
        this.spinner.show();
        let body = new RequestModel(Home.GET_BANNER_PURCHASE_MORTGAGE);
        let url = `/${PUBLIC_URL.home}/${HOME.getBannerPurchaseMortgage}`
        return this.httpClientService.post<string[]>(url, body)
            .then(resp => {
                return resp;
            })
            .catch(err => {
                let data = [] as string[];
                data = [
                    'https://billionb.dev-asha.com/images/asset/dafc18923bbb79dc4cf78a7be87e2659.png',
                ]
                return data;
            })
            .finally(() => {
                this.spinner.hide();
            })
    }

    // getColorLand(): Promise<any> {
    //     let url = `/${ROOT_URL.api}/${HOME.get_property_color_land}`
    //     return this.httpClientService.get<any>(url,false)
    //         .then(resp => {
    //           //  console.log(resp)
    //         })
    //         .catch(err => {
    //             // let resp = {} as DemandResponce;
    //             // let data = this.dataTest;
    //             // resp.data = data;
    //             // resp.pagination = {
    //             //     currentPage: 1,
    //             //     pageSize: 10,
    //             //     totalPages: 10,
    //             //     totalRecords: 99
    //             // } as PaginationResponse;
    //             // resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
    //             // return resp
    //         });
    // }

    getColorLand(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${HOME.get_property_color_land}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                this.listColorLand = resp.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    } as Options
                })
                return resp
            });
    }

    getPropertyType(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_property_type}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                this.listPropertyType = resp.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    } as Options
                })
                return resp
            });
    }

    searchMatchAsset(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.asset_matching_page}`
        return this.httpClientService.post<SearchAssetListResp>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                resp.data = resp.data?.map(item => {
                    return {
                        ...item,
                        property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                        property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label
                    }
                })
                return resp;
            })
    }

    searchMatchLocationAsset(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.asset_matching_location_nearby_page}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    searchFavoriteInquiry(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.inquiry_favorite_page}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse);
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        asset: {
                            ...item.inquiry,
                            property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                            property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label,
                        },
                        isSelect: false,
                        isFavorite: true
                    }
                })
                return resp;
            })
    }
    searchFavoriteAsset(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.asset_favorite_page}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse);
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        asset: {
                            ...item.asset,
                            property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                            property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label,
                        },
                        isSelect: false,
                        isFavorite: true
                    }
                })
                return resp;
            })
    }
    searcRequireAsset(bodys: SearchInqueryListReq): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.getInqueryPage}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse);
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        asset: {
                            ...item.asset,
                            property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                            property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label,
                        },
                        isSelect: false,
                        isFavorite: true
                    }
                })
                return resp;
            })
    }

    searchMeetingAsset(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.asset_meeting_date_page}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse);
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        asset: {
                            ...item.asset,
                            property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                            property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label,
                        },
                    }
                })
                return resp;
            })
    }

    favoriteAssetItem(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.asset_favorite}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    getMemberDashbord(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.get_dashboard_asset_by_member}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    searchMatchInquiry(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.inquiry_matching_page}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                resp.data = resp.data.map(item => {
                    return {
                        ...item,
                        property_type_detail: this.listPropertyType.find(i => i.value == item.property_type_id)?.label,
                        property_color_land_detail: this.listColorLand.find(i => i.value == item.property_color_land_id)?.label,
                        isSelect: false
                    }
                })

                return resp
            })
    }

    presentAsset(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.present_asset}`
        return this.httpClientService.post<any>(url, bodys, true)
            .then(resp => {
                return resp;
            })
    }

    getFaq(): Promise<any> {
        let bodys = {};
        let url = `/${ROOT_URL.api}/${HOME.get_faq}`
        return this.httpClientService.get<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    getVendor(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.get_vendor}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    getVendorByID(bodys: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${HOME.get_vendor_company}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }
    countView(Id: any): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${HOME.count_view_vendor}/${Id}`
        return this.httpClientService.get<any>(url,data)
            .then(resp => {
                return resp;
            })
    }

    getBanner(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/${PUBLIC_URL.api}/${HOME.get_banner}`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }
    asset_favorite(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/api/asset_favorite`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }
    inquiry_favorite(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/api/inquiry_favorite`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }
    check_course_member(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/api/check_course_member_active`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }
    payment_course(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/api/payment_course`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }
    callback_payment(bodys: any): Promise<string[]> {
        // this.spinner.show();
        let url = `/api/confirm_payment_course`
        return this.httpClientService.post<any>(url, bodys)
            .then(resp => {
                return resp;
            })
            // .finally(() => {
            //     this.spinner.hide();
            // })
    }

    get_manual() {
        let bodys = {};
        let url = `/${ROOT_URL.api}/${HOME.get_manual}`
        return this.httpClientService.get<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    get_banner_footer() {
        let bodys = {};
        let url = `/${ROOT_URL.api}/${HOME.get_banner_footer}`
        return this.httpClientService.get<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }

    get_icon_profile() {
        let bodys = {};
        let url = `/${ROOT_URL.api}/${HOME.getIconProfile}`
        return this.httpClientService.get<any>(url, bodys)
            .then(resp => {
                return resp;
            })
    }
}
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FORM_URL, ROOT_URL } from "../constants/api-route.constant";
import { HttpClientService } from "./http-client.service";

@Injectable({
    providedIn: 'root'
})
export class FormService {

    isLogOut: boolean = false;

    constructor(
        private httpClientService: HttpClientService,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) {}

    getInqueryType(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_inquiry_type}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getLocation(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_property_location_nearby}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getPropertyType(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_property_type}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getTimeRent(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_property_period}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getProvince(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_province}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }
    getDistrict(province_id): Promise<any> {
        let data = {province_id: province_id};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_district}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getSubDistrict(district_id): Promise<any> {
        let data = {district_id: district_id};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_sub_district}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getFormByPropertyType(propertyType: string): Promise<any> {
        let data = {property_type_id: propertyType};
        
        let url = `/${ROOT_URL.api}/${FORM_URL.get_form}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getPropertyOwnership(property_type_id: string): Promise<any> {
        // let data = {property_type_id: property_type_id};
      //  console.log("propertyType", property_type_id);
        
        let url = `/${ROOT_URL.api}/get_property_ownership?property_type_id=${property_type_id}`
        return this.httpClientService.get<any>(url, true)
            .then(resp => {
                return resp
            });
    }

    addForm(data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.asset}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    addInquery(data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.inquiry}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getInquiryById(inquiry_id: number): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_inquiry_by_id}/${inquiry_id}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getAssetById(inquiry_id: number): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${FORM_URL.get_asset_by_id}/${inquiry_id}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    updateInquery(data: any, inquiry_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.inquiry}/${inquiry_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    updateAsset(data: any, asset_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.asset}/${asset_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    hideAsset(data: any, asset_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.change_status_asset}/${asset_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    finishAsset(data: any, asset_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.finish_asset}/${asset_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    deleteAsset(asset_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.asset}/${asset_id}`
        return this.httpClientService.delete<any>(url, true)
            .then(resp => {
                return resp
            });
    }


    hideInquiry(data: any, inquiry_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.change_status_inquery}/${inquiry_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    finishInquiry(data: any, inquiry_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.finish_inquery}/${inquiry_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    deleteInquiry(inquiry_id: string): Promise<any> {
        let url = `/${ROOT_URL.api}/${FORM_URL.inquiry}/${inquiry_id}`
        return this.httpClientService.delete<any>(url, true)
            .then(resp => {
                return resp
            });
    }

    getFacilityById(id: number): Promise<any> {
        let data = {property_facility_id: id}
        let url = `/${ROOT_URL.api}/${FORM_URL.get_property_sub_facility}`
        return this.httpClientService.post<any>(url,data, true)
            .then(resp => {
                return resp
            });
    }
    
    getdorpdown_member_type_by_group(id: number): Promise<any> {
        let data = {member_group_id: id}
        let url = `/api/dorpdown_member_type_by_group`
        return this.httpClientService.post<any>(url,data, true)
            .then(resp => {
                return resp
            });
    }

    getTagsList(id: number): Promise<any> {
        let data = {property_type_id: id};
        let url = `/${ROOT_URL.api}/get_form`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }



}
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PaginationResponse } from "../common/paginations";
import { ASSET_URL, MEMBER_URL, ROOT_URL } from "../constants/api-route.constant";
import { ROOT_ROUTES } from "../constants/routes.constant";
import { MemberSignUpRequest } from "../models/member-model";
import { NotificationUtil } from "../utils/notification.util";
import { PaginationUtil } from "../utils/paination.util";
import { AuthenService } from "./auth.service";
import { HttpClientService } from "./http-client.service";

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    constructor(
        private httpClientService: HttpClientService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private auth: AuthenService
        // private confirmPopupService: ConfirmPopupService,
        // private apiDisplayConfigService: ApiDisplayConfigService,
    ) {
        
    }

    getMemberGroup(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_member_group}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }
    getDropMember(){
        let data = {};
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_member_group}`
        return this.httpClientService.get<any>(url, data)
        .then(resp => {
              //  console.log(resp,"RESP")
                return resp
            });
    }

    registerMember(data: MemberSignUpRequest): Promise<any> {
        let url = `/${ROOT_URL.authen}/${MEMBER_URL.register_member}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                NotificationUtil.success('ลงทะเบียนสำเร็จ').then(() => {
                    const redirectTo = ROOT_ROUTES.auth;
                    this.router.navigate(['/', redirectTo]);
                  });
            });
    }

    getMemberById(member_id: number): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${MEMBER_URL.member}/${member_id}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    verifyCitizen(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.confirm_citizen_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    verifyEmail(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.confirm_email_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    verifyOtp(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.confirm_otp_email_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    verifyInfomation(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.add_infomation_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    updateProfile(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.update_profile_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }
    
    getAssetById(id: number): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${ASSET_URL.get_asset_by_id}/${id}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getPermissionsCreate(): Promise<any> {
        let data = {};
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_permission_post_member}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }
    
    resetPassword(member_id: number, data: any): Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.reset_password_member}/${member_id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    forgotPassword(data: any) : Promise<any> {
        let url = `/${ROOT_URL.authen}/${MEMBER_URL.forgot_password_member}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    getMemberComent(data: any) : Promise<any> {
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_member_comment}`
        return this.httpClientService.post2<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    update_member_group(id:number, data: any){
        let url = `/${ROOT_URL.api}/${MEMBER_URL.update_member_group}/${id}`
        return this.httpClientService.put<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }
    update_member_group1( data: any){
        let url = `/${ROOT_URL.api}/${MEMBER_URL.update_member_group}`
        return this.httpClientService.post<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    get_focat_asset_member(data: any){
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_focat_asset_member}`
        return this.httpClientService.post<any>(url, data)
            .then(resp => {
                return resp
            });
    }

    getAssetDashbordPage(data: any){
        let url = `/${ROOT_URL.api}/${MEMBER_URL.asset_dashboard_page}`
        return this.httpClientService.post<any>(url, data)
            .then(resp => {
                resp.pagination = PaginationUtil.setPaginationData(resp);
                resp.pagination = PaginationUtil.setFirstSeq(resp.pagination as PaginationResponse)
                return resp
            });
    }

    get_notification_member(){
        let data = {};
        let url = `/${ROOT_URL.api}/${MEMBER_URL.get_notification_member}`
        return this.httpClientService.get<any>(url, data, true)
            .then(resp => {
                return resp
            });
    }

    read_notification_member(data: any){
        let url = `/${ROOT_URL.api}/${MEMBER_URL.read_notification_member}`
        return this.httpClientService.post<any>(url, data)
            .then(resp => {
                return resp
            });
    }

   
}
export enum ContactUS {
    SAVE_CONTACT_US = "saveContactUs"
}

export enum Review {
    LIST_REVIEW = "listReview",
    GET_RATING = "getRating"
}

export enum DownloadFile {
    GET_BASE64 = "getBase64"
}

export enum Blog {
    LIST_NEWS = "listNews",
    FILTER_NEWS = "filterNews",
}

export enum Course {
    LIST = "list",
    FILTER = "filter",
}


export enum Home {
    GET_HOME_DETAIL = "getHomeDetail",
    GET_HOME_BANNER = "getHomeBanner",
    SEARCH_NEW_DEMAND_PURCHASE = "searchNewDemandPurchase",
    GET_BANNER_NEW_PROJECT = "getBannerNewProject",

    SEARCH_DEMAND_PURCHASE = "searchDemandPurchase",

    SEARCH_DEMAND_RENT = "searchDemandRent",
    GET_BANNER_RENT = "getBannerRent",

    SEARCH_SALE_MORTGAGE = "searchSaleMortgage",

    SEARCH_PURCHASE_MORTGAGE = "searchPurchaseMortgage",
    GET_BANNER_PURCHASE_MORTGAGE = "getBannerPurchaseMortgage",

}

export enum Login {
    LOGIN = "login",
    LOGOUT = "logout",
    RESEND_AUTHEN = "check_login"
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { CryptoKeyConstant } from 'src/app/@core/constants/crypto.constant';
import {
  BUYER_ROUTES,
  DETAIL_ROUTES,
  INQUIRY_ROUTES,
  PROFILE_ROUTES,
  PUBLIC_ROUTES,
  ROOT_ROUTES,
  SALLER_ROUTES,
} from 'src/app/@core/constants/routes.constant';
import { UserProfile } from 'src/app/@core/models/user-profile';
import { AuthenService } from 'src/app/@core/services/auth.service';
import { ConfirmPopupService } from 'src/app/@core/services/confirm-popup.service';
import { HomeService } from 'src/app/@core/services/home.service';
import { LoginService } from 'src/app/@core/services/login.service';
import { MemberService } from 'src/app/@core/services/member.service';
import { EncryptDecryptUtil } from 'src/app/@core/utils/encrypt-decrypt.util';
import { NotificationUtil } from 'src/app/@core/utils/notification.util';
import { TimeUtil } from 'src/app/@core/utils/time.util';
import { environment } from 'src/environments/environment';
import { FormService } from 'src/app/@core/services/form.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  activeMenu: string = 'home';

  lang: string = 'th';

  /** Language */
  langOptions = [
    { label: 'ไทย', value: 'th', icon: 'pi pi-refresh' },
    { label: 'Eng', value: 'en', icon: 'pi pi-refresh' },
  ] as any[];

  countries = [
    { name: 'Thai', value: 'th' },
    { name: 'English', value: 'en' },
  ];
  buyer_type 
  // buyer_type = [
  //   { name: 'ผู้ซื้อ', value: 'buyer' },
  //   { name: 'ผู้เช่า', value: 'tenent' },
  //   { name: 'ผู้ขาย', value: 'owner' },
  //   { name: 'ผู้ให้เช่า', value: 'owner_tenent' },
  //   { name: 'เอเจน', value: 'agent' },
  //   { name: 'developer', value: 'developer' },
  //   { name: 'investor', value: 'investor' },
  //   { name: 'บุคคลทั่วไป', value: 'person' },
  // ];

  banner_main: string[] = [];

  langSrc = '';

  btoptions:any;

  Display = true;
  isDisplay = true;
  isDisplay1 = true;
  isDisplay2 = true;

  isDisplayMainDialog!: boolean;
  isDisplayAddDemandBuyer!: boolean;
  changestatus!: boolean;
  memberGroup: any;
  form: FormGroup;

  selectedCountry: any = { name: 'Thai', value: 'th' };
  username: string;

  confirmCheckBox: boolean = false;
  confirmCheckBoxShared: boolean = false;
  
  PermissionsCreate: boolean = false;
  bg_banner: boolean = false;

  dataCondition: any;

  items: MenuItem[] = [];
  notiItems: MenuItem[] = [];

  condition: string = '';
  share_condition: string = '';

  member: string = '';
  badge: any;

  get IsLogin(): boolean {
    if (this.authenService.isAuthenticated()) {
      this.setMenu();
      return true;
    } else {
      return false;
    }
  }

  get userProfile(): UserProfile {
    return this.authenService.getProfile() as UserProfile;
  }

  constructor(
    private homeService: HomeService,
    private router: Router,
    public translate: TranslateService,
    private authenService: AuthenService,
    private loginService: LoginService,
    private FormService: FormService,
    private confirmPopupService: ConfirmPopupService,
    private memberService: MemberService,
    private fb: FormBuilder
  ) {
    
  this.isDisplay = true;
  this.isDisplay1 = true;
  this.isDisplay2 = true;

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        /**
         * home หน้าหลัก
         * course คอร์สเรียนอสังหาฯ
         *  */
        let data = event.snapshot.data;
        if (data.activeMenu) {
          this.activeMenu = data.activeMenu;
        }
      }
    });

    this.loginService.getCondition();

    var lang = localStorage.getItem('lang') || 'th';
    this.selectedCountry = this.countries.find((co) => co.value === lang);
    if (lang) {
      this.translate.use(lang);
      this.lang = lang;
    } else {
      this.translate.use('th');
    }

    this.langSrc = 'assets/images/' + this.selectedCountry.value + '-flag.png';
  }

  get labelBtn(): string {
    // console.log(this.userProfile.member_group_id,"ดูไอดี");
    
    if (this.userProfile?.member_group_id === 1 || this.userProfile?.member_group_id === 2) {
      this.share_condition = this.dataCondition?.share_condition_inquiry;
        this.condition = this.dataCondition?.condition_inquiry;
        this.member = this.dataCondition?.text_check_condition_inquiry;
        // console.log(this.condition);
        // console.log('2');
        this.isDisplay = true;
      return 'ต้องการซื้อ';
    } else if(this.userProfile?.member_group_id === 3 || this.userProfile?.member_group_id === 4){
      this.share_condition = this.dataCondition?.share_condition_asset;
        this.condition = this.dataCondition?.condition_asset;
        this.member = this.dataCondition?.text_check_condition_asset;
        // console.log(this.condition);
        // console.log('1');
        this.isDisplay = false;
      return 'ต้องการซื้อ';
    }
     else if(this.userProfile?.member_group_id === 5){
      if(this.btoptions==1){
        this.share_condition = this.dataCondition?.share_condition_inquiry;
        this.condition = this.dataCondition?.condition_inquiry;
        this.member = this.dataCondition?.text_check_condition_inquiry;
        // console.log(this.condition);
        // console.log('2');
        this.isDisplay = true;
        return 'ต้องการซื้อ'
      }else if(this.btoptions==2){

        this.share_condition = this.dataCondition?.share_condition_asset;
          this.condition = this.dataCondition?.condition_asset;
          this.member = this.dataCondition?.text_check_condition_asset;
          // this.isDisplay = false;
        return 'ต้องการซื้อ'
      }
      return 'ต้องการซื้อ'
     
    } else{
      
      return 'ต้องการซื้อ'
    }

  }
  get labelBtn1(): string {
    
    if (this.userProfile?.member_group_id === 1 || this.userProfile?.member_group_id === 2 ) {
      this.share_condition = this.dataCondition?.share_condition_inquiry;
        this.condition = this.dataCondition?.condition_inquiry;
        this.member = this.dataCondition?.text_check_condition_inquiry;
        // console.log(this.condition);
        // console.log('2');
        this.isDisplay1 = false;
      return null;
    } else if( this.userProfile?.member_group_id === 3 || this.userProfile?.member_group_id === 4){
      this.share_condition = this.dataCondition?.share_condition_asset;
        this.condition = this.dataCondition?.condition_asset;
        this.member = this.dataCondition?.text_check_condition_asset;
        // console.log(this.condition);
        // console.log('1');
        this.isDisplay1 = true;
      return 'ต้องการขาย';
    }
     else if(this.userProfile?.member_group_id === 5){
      if(this.btoptions==1){
        this.share_condition = this.dataCondition?.share_condition_inquiry;
        this.condition = this.dataCondition?.condition_inquiry;
        this.member = this.dataCondition?.text_check_condition_inquiry;
        // console.log(this.condition);
        // console.log('2');
        // this.isDisplay1 = false;
        return 'ต้องการขาย'
      }else if(this.btoptions==2){

        this.share_condition = this.dataCondition?.share_condition_asset;
          this.condition = this.dataCondition?.condition_asset;
          this.member = this.dataCondition?.text_check_condition_asset;
          this.isDisplay1 = true;
        return 'ต้องการขาย'
      }
      return 'ต้องการขาย'
    }
    else{
      return 'ต้องการขาย'
    }

  }

  ngOnInit(): void {

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // The URL path has changed
    //     const currentPath = this.router.url;
    //   //  console.log("Current Path: " + currentPath);
    //     if (currentPath == '/' || currentPath == '') {
    //       this.bg_banner = true
    //     } else {
    //       this.bg_banner = false
          
    //     }

    //     // You can perform any actions or updates based on the path change here
    //   }
    // });

    this.getBanner();
    this.loginService.reAuthen().then(() => {
      this.dataCondition = this.loginService.configData;
      // console.log(this.dataCondition);


      // if(dataCondition != null){
      // if (this.userProfile?.member_group_id === 2) {
      //   this.share_condition = dataCondition.share_condition_asset;
      //   this.condition = dataCondition.condition_asset;
      //   this.member = dataCondition.text_check_condition_asset;
      //   console.log(this.condition);
      //   console.log('1');
      // } else if (this.userProfile?.member_group_id === 3) {
      //   this.share_condition = dataCondition.share_condition_inquiry;
      //   this.condition = dataCondition.condition_inquiry;
      //   this.member = dataCondition.text_check_share_condition_inquiry;
      //   console.log(this.condition);
      //   console.log('2');
      // }
      // console.log('3');
      // }
    });

    this.setMenu();
    if (this.IsLogin) this.getNoti();
    TimeUtil.delay(3000).then(() => {});
    this.form = this.fb.group({
      member_group_id: '1',
      buyer_type: 'buyer',
    });

    if (this.IsLogin) {
      this.memberService.getPermissionsCreate().then((resp) => {
        // console.log(resp, 'resp');
        this.PermissionsCreate = resp;
        
        if (this.PermissionsCreate ) {
          this.Display = false;
          // console.log('true');
        } else {
          this.isDisplay = false;
          this.isDisplay1 = false;
          // this.changestatus = false;
          // console.log('false');
          
        }
  
      });
      
    }
    
    // this.assign()
  }

  async assign() {
    this.banner_main = await this.getAllBannerByPosition('banner_main')

  // console.log("banner_main", this.banner_main);
  // console.log("banner_home_1", this.banner_home_1);
  // console.log("banner_home_2", this.banner_home_2);
  // console.log("banner_home_3", this.banner_home_3);
  // console.log("banner_home_4", this.banner_home_4);
    

  }
  async getAllBannerByPosition(position:any) {
    let img =[]
    let data = {
      type: 'banner',
      // position: 'banner_main',
      position: position,
      status: 1,
      draw: 1,
      columns: [],
      order: [
        {
          column: 0,
          dir: "asc"
        }
      ],
      start: 0,
      length: 100,
      search: {
        value: "",
        regex: false
      }
    } as any
    await this.homeService.getBanner(data).then(res => {
    // console.log("res", res);
      
      img = res?.map((item:any) => {
        return {
          image: item.image,
          url: item.url,
        }
        // return item.image as string
        // // return item.image as string
      })
    console.log("img", position, img);
    })
    
    return img
  }
  ngAfterViewInit() {
    // Check the initial URL path when the component has been initialized
    const currentPath = this.router.url;
    // console.log("Current Path: " + currentPath);
    
    // Set background condition based on the initial path
    if (currentPath === '/' || currentPath === '') {
      this.bg_banner = true;
    } else {
      this.bg_banner = false;
    }

    // Subscribe to future navigation events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // The URL path has changed
        const newPath = this.router.url;
        // console.log("New Path: " + newPath);

        // Update background condition based on the new path
        if (newPath === '/' || newPath === '') {
          this.bg_banner = true;
        } else {
          this.bg_banner = false;
        }

        // You can perform any other actions or updates based on the path change here
      }
    });
  }

  goToHome(): void {
    // const buildPortalOnly = environment?.buildPortal && !environment?.buildPublic

    const buildPortalOnly = environment && !environment;
    // const redirectTo = ROOT_ROUTES.public;

    this.router.navigate(['/']);
    this.isDisplayMainDialog = false;
  }

  goYoutube() {
    // console.log(this.loginService.configData)
    // let data = this.loginService.configData;
    // if(data.url_youtube_channel){
    //   window.open(data.url_youtube_channel, '_blank');
    // }

    this.router.navigate(['/']);

    TimeUtil.delay(500).then(() => {
    //  console.log('123');
      const element = document.getElementById('videoYoutube');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  goTo(path: any) {
    this.router.navigate([path]);
    this.isDisplayMainDialog = false;
  }

  goToCourse(): void {
    this.router.navigate(['/', PUBLIC_ROUTES.course]);
    this.isDisplayMainDialog = false;
  }

  onMenuButtonClick(event: Event) {
    // this.menuButtonClick.emit();
    event.preventDefault();
  }

  changeLang(event: any) {
    this.translate.use(event.value.value);
    localStorage.setItem('lang', event.value.value);
  }

  setMenu() {
    /* Set Name */
    let name = this.userProfile?.fname + ' ' + this.userProfile?.lname;
    /* default menu */
    this.items = [
      {
        label: name,
        disabled: true,
        command: () => {
          // this.onChangeUserRole(record);
        },
        id: 'top-menu-bar-name',
      },
      {
        label: this.userProfile?.email,
        icon: 'far fa-envelope',
        disabled: true,
        command: () => {
          // this.onChangeUserRole(record);
        },
        id: 'top-menu-bar-email',
      },
      {
        label: 'โปรไฟล์ของฉัน',
        icon: 'fas fa-user',
        command: () => {
          this.goProfile();
        },
        id: 'top-menu-bar-logout',
      },

      {
        label: 'Logout',
        icon: 'fas fa-sign-out-alt',
        command: () => {
          this.logout();
        },
        id: 'top-menu-bar-logout',
      },
    ];
  }

  logout() {
    this.confirmPopupService
      .confirm({
        message: `ยืนยันการออกจากระบบ ?`,
      })
      .then(() => this.loginService.logout());
  }

  goProfile() {
    this.router.navigate(['/', ROOT_ROUTES.profile, PROFILE_ROUTES.page]);
  }

  addDamand() {
    if (this.userProfile.member_group_id === 1) {
      this.addDemandBuyer();
    } else if (this.userProfile.member_group_id === 2) {
      this.addDemandSaller();
    
    } else if (this.userProfile.member_group_id === 3) {
      if(this.btoptions==2){

        this.addDemandSaller();
      }else {
        this.addDemandBuyer();
      }
    }
  }

  addDemandBuyer() {
    this.router.navigate(['/', ROOT_ROUTES.inquiry, INQUIRY_ROUTES.form]);
  }

  addDemandSaller() {
    this.router.navigate(['/', ROOT_ROUTES.saller, SALLER_ROUTES.form]);
  }
  submit() {
    // console.log(this.form.value, 'form');
    this.memberService.update_member_group1(this.form.value).then(async (resp) => {
    //  console.log(resp, 'resp');
     if (resp?.status) {
       await NotificationUtil.success('เปลี่ยนสถานะสำเร็จ', '');
       this.changestatus = false;
     }
    });
  }

  searchInquiryAdvance() {
    let data = {};

    const encryptedQueryParamsData = EncryptDecryptUtil.encryptAES(
      JSON.stringify(data),
      CryptoKeyConstant.Search
    );

    this.router.navigate(
      ['/', ROOT_ROUTES.detail, DETAIL_ROUTES.page, DETAIL_ROUTES.search],
      {
        queryParams: { data: encryptedQueryParamsData },
      }
    );
  }

  create(num:any) {
    this.btoptions = num
    if (this.IsLogin == false) {
      this.goTo('/auth/login');
    } else {
      if (
        this.userProfile.buyer_type === 'owner' ||
        this.userProfile.buyer_type === 'agent'
      ) {
        this.memberService.getPermissionsCreate().then((resp) => {
          if (resp) {
            this.isDisplayAddDemandBuyer = true;
          } else {        
            NotificationUtil.invalid(
              '',
              'กรุณายืนยันตัวตนให้เรียบร้อยก่อนทำรายการ'
              ).then(() => {
                this.goProfile();
              });
            }
          });
        } else {
          this.isDisplayAddDemandBuyer = true;
        // NotificationUtil.invalid('', 'คุณไม่สามารถทำรายการได้').then(() => {
        //   this.goProfile();
        // });
      }
    }
  }

  getNoti() {
    this.memberService.get_notification_member().then((resp) => {
      resp = resp.filter((data) => data.read == 0);
      this.badge = resp.length;
      this.setNotiItems(resp);
    });
  }

  setNotiItems(data: any) {
    let notiItems = [];
    if (data && data.length > 0) {
      data.forEach((d) => {
        let item = {
          label: d.message,
          title: d.title,
          type: d.type,
          taget_id: d.taget_id,
          command: () => {
            this.readNoti(d);
          },
        };
        notiItems.push(item);
      });
    } else {
      let item = {
        title: 'ไม่มีการแจ้งเตือน',
      };
      notiItems.push(item);
    }
    this.notiItems = notiItems;
  }

  readNoti(noti) {
    let data = {
      notification_member_id: noti.id,
    };
    if (noti.type == 'asset') {
      this.router.navigate([
        '/',
        ROOT_ROUTES.detail,
        DETAIL_ROUTES.page,
        DETAIL_ROUTES.view_asset,
        noti.taget_id,
      ]);
    } else if (noti.type == 'inquiry') {
      this.router.navigate([
        '/',
        ROOT_ROUTES.detail,
        DETAIL_ROUTES.page,
        DETAIL_ROUTES.inquery,
        noti.taget_id,
      ]);
    }
    this.memberService.read_notification_member(data).then((resp) => {});
  }

  getMemberGroup() {
    this.memberService.getDropMember().then((res) => {
      this.memberGroup = res;
    //  console.log(res, 'Res');
    });
    this.change();
  }

  async change() {
    await this.FormService.getdorpdown_member_type_by_group(this.form.value.member_group_id).then(resp => {
      //  console.log(resp);
        this.buyer_type = resp
        // if (resp.length == 1) {
          // this.form.value.buyer_type = resp[0];
        // }
      })
  }

  opendialog() {
    this.isDisplayMainDialog = true
  }
  getBanner() {
    let data = {
      type: 'banner',
      position: 'banner_main',
      status: 1,
      draw: 1,
      columns: [],
      order: [
        {
          column: 0,
          dir: "asc"
        }
      ],
      start: 0,
      length: 100,
      search: {
        value: "",
        regex: false
      }
    } as any
    this.homeService.getBanner(data).then((item: any) => {
    // console.log("data", item);
    this.banner_main = item[0]?.image
    
      // this.banner_main = item?.map((items:any) => {
      //   return items.image as string
      // })

      // console.log("this.banner_main",this.banner_main);
      
    })
  }
}

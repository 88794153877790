import { SearchAssetListResp } from './../models/home-model';
import { PaginationResponse } from '../common/paginations';
import { PaginationConstant } from '../constants/pagination.constant';
import { SearchInqueryListResp } from '../models/home-model';
import { TranslateUtil } from './translate.util';

export const PaginationUtil = {
  setFirstSeq(paginationResp: PaginationResponse): PaginationResponse {
    let firstRecord = 1;
    if (paginationResp) {
      if (
        paginationResp.pageSize &&
        paginationResp.currentPage &&
        paginationResp?.currentPage != 1
      ) {
        firstRecord =
          (paginationResp.currentPage - 1) * paginationResp.pageSize + 1;
      }
      paginationResp.firstRecord = firstRecord;
    } else {
      paginationResp = PaginationConstant.DefaultResponsePagination;
    }
    return paginationResp;
  },

  async setCurrentPageReport(paginationResp: PaginationResponse): Promise<string> {
    if (paginationResp && Object.keys(paginationResp).length !== 0) {
      const pageRes: PaginationResponse = { ...paginationResp };

      const firstItemNumber =
        (pageRes?.currentPage as number) * (pageRes?.pageSize as number) -
        ((pageRes?.pageSize as number) - 1);

      let lastItemNumber: number;

      const totalItemNumber: number = paginationResp?.totalRecords as number;

      if (pageRes.currentPage !== pageRes.totalPages) {
        lastItemNumber =
          (pageRes?.currentPage as number) * (pageRes?.pageSize as number);
      } else {
        lastItemNumber = paginationResp?.totalRecords as number;
      }

      return `${firstItemNumber} - ${lastItemNumber} ${await TranslateUtil.translateText('จาก')} ${totalItemNumber} ${await TranslateUtil.translateText('รายการ')}`;
    } else {
      return '';
    }
  },

  setPaginationData(data: any) {
    data.pagination = {
      currentPage: data.current_page,
      pageSize: data.per_page,
      totalPages: data.last_page,
      totalRecords: data.total,
      firstRecord: data.from,
    }
    return data.pagination;
  }
};

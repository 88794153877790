import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Options } from '../constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class ApiDisplayConfigService {

  configs: BehaviorSubject<Options[]> = new BehaviorSubject<Options[]>([]);
  configsLogout: BehaviorSubject<Options[]> = new BehaviorSubject<Options[]>([]);
  configsUiAuthen: BehaviorSubject<Options[]> = new BehaviorSubject<Options[]>([]);

  constructor(
  ) { }

  setConfig(values: Options[]) {
    this.configs?.next(values);
    return this.configs?.asObservable()
  }

  getUiDisplyConfigByKey(key: string): Options | undefined {
    const groupUiDisplay = this.configs?.getValue();
    const enableReqid = groupUiDisplay?.find(element => element?.value == key);
    return enableReqid;
  }

  getUiDisplyConfigValueByKey(key: string): String {
    const config = this.getUiDisplyConfigByKey(key)
    return config?.label as string;
  }

  setConfigLogout(values: Options[]) {
    this.configsLogout?.next(values);
    return this.configsLogout?.asObservable()
  }

  getLogoutConfigByKey(key: string): Options | undefined {
    const groupUiDisplay = this.configsLogout?.getValue();
    const enableReqid = groupUiDisplay?.find(element => element?.value == key);
    return enableReqid;
  }

  getLogoutConfigValueByKey(key: string): String {
    const config = this.getLogoutConfigByKey(key)
    return config?.label as string;
  }

  setConfigUiAuthen(values: Options[]) {
    this.configsUiAuthen?.next(values);
    return this.configsUiAuthen?.asObservable()
  }

  getUiAuthenConfigByKey(key: string): Options | undefined {
    const groupUiDisplay = this.configsUiAuthen?.getValue();
    const enableReqid = groupUiDisplay?.find(element => element?.value == key);
    return enableReqid;
  }

  getUiAuthenConfigValueByKey(key: string): String {
    const config = this.getUiAuthenConfigByKey(key)
    return config?.label as string;
  }

}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CryptoKeyConstant } from "../constants/crypto.constant";
import { UserProfile } from "../models/user-profile";
import { EncryptDecryptUtil } from "../utils/encrypt-decrypt.util";

@Injectable({
    providedIn: 'root'
})
export class AuthenService {

    profile!: UserProfile;
    public profile$ = new BehaviorSubject<UserProfile | null>(null);

    token!: string;
    static token: any;

    getProfile(): UserProfile | null {
        return this.profile$.value;
    }


    setProfile(profile: UserProfile) {
        profile = {
            ...profile,
        };
        let profileEncrypted = EncryptDecryptUtil.encryptAESObject(profile, CryptoKeyConstant.Authen);
        sessionStorage.setItem('profile', EncryptDecryptUtil.encryptMassage(profileEncrypted));
        return this.profile$.next(profile);
    }

    setToken(token: string) {
        this.token = token;
        let reAuthenTokenEncrypted = EncryptDecryptUtil.encryptAES(token, CryptoKeyConstant.Authen);
        sessionStorage.setItem('reAuthenToken', EncryptDecryptUtil.encryptMassage(reAuthenTokenEncrypted));
        let currentDate = new Date();
        let encrypted = EncryptDecryptUtil.encryptAES(currentDate.getTime().toString(), CryptoKeyConstant.Authen);
        sessionStorage.setItem('Time', EncryptDecryptUtil.encryptMassage(encrypted));
    }

    getToken() {
        return this.token;
    }

    public isAuthenticated(): boolean {
        if (sessionStorage.getItem("reAuthenToken")) {
          return true;
        }
        else {
          return false;
        }
      }
}
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CONTACT_US_URL, PUBLIC_URL } from "../constants/api-route.constant";
import { ContactUS } from "../constants/service-name.constant";
import { ContactUsReq } from "../models/contact-us/contact-us";
import { RequestModel } from "../models/request-model";
import { HttpClientService } from "./http-client.service";

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    isLogOut: boolean = false;

    constructor(
        private httpClientService: HttpClientService,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) {}

    saveContactUs(bodys: ContactUsReq): Promise<any> {
        let url = `/${PUBLIC_URL.api}/${CONTACT_US_URL.save}`
        return this.httpClientService.post<any>(url, bodys)
        .then(resp => {
            return resp;
        })
        .catch(err => {
          //  console.log(err)
        });
    }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/@core/services/login.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {

  quickLink: any[] = []
  services: any[] = []
  contact: any = {};

  short_description: string;
  phone1: string;
  fax: string;
  email: string;
  address: string;

  get IsAuthPage(): boolean {
    const urlPathArray: string[] = this.router.url.split('/');

    if (urlPathArray[1] !== 'auth') {
      return true;
    }
    else return false;
  }

  constructor(
    public translate: TranslateService,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.getQuickLink();
    this.getServices();
    this.getConfigdata();
    
  }

  get isThai(): boolean {
    return this.translate.currentLang == 'th';
  }

  async getConfigdata() {
    let data = this.loginService.configData;
    if (!data || data === undefined) {
      await this.loginService.getCondition().then(res => {
        data = res;
      })
    }
    this.setConfigData(data);
  }

  setConfigData(data: any) {
    if (data) {
      this.phone1 = data.tel1;
      this.fax = data.tel2;
      this.email = data.email1;
      this.address = data.address;
      this.short_description  = data.shot_desception_web
    }
    this.getContact();

  }

  getQuickLink() {
    this.quickLink = [
      {
        labelTh: 'เกี่ยวกับเรา',
        labelEn: 'Who we are',
        link: "/about-us"
      },
      {
        labelTh: 'ติดต่อเรา',
        labelEn: 'Contact Us',
        link: "/contact-us"
      },
      {
        labelTh: 'ข่าวสาร',
        labelEn: 'Blog',
        link: "/blog"
      },
      {
        labelTh: 'คำถามที่พบบ่อย',
        labelEn: 'FAQs',
        link: "/faq"
      },
      
    ];
  }

  getServices() {
    this.services = [
      // {
      //   labelTh: 'โปรไฟล์ของฉัน',
      //   labelEn: 'Order tracking',
      //   link: "/public/page/about-us"
      // },
      {
        labelTh: 'โปรไฟล์ของฉัน',
        labelEn: 'Profile',
        link: "/profile/page"
      },
    ];
  }

  getContact() {
    this.contact = {
      address: this.address,
      phone: this.phone1,
      fax: this.fax,
      email: this.email
    }
  }

}

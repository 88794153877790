<div class="layout-footer py-4">
  <div class="subscrib" *ngIf="IsAuthPage">
    <app-subscribe-footnote></app-subscribe-footnote>
  </div>
  <div class="container-lg layout-footer-container mt-2">
    <div class="row justify-content-between align-items-center flex-column flex-md-row flex-nowrap gap-3">
      <div class="col-md-4 py-2 mb-3 text-center">
        <div class="footnote-logo">
          <a class="d-flex justify-content-center align-items-center w-full">
            <img class="logo-image" alt="OCS" style="width: 70%;" [src]="'assets/images/logo-footnote.png'" />
          </a>
        </div>
        <span>{{short_description | translate}}
        </span>
      </div>
      <div class="col-md-7 d-flex justify-content-between align-items-start flex-md-nowrap">
        <div class="col-lg-4 py-2 mb-3">
          <h4 class="header-text">{{'Quick Link' | translate }}</h4>
          <ng-container *ngFor="let menu of quickLink">
            <div class="mb-2">
              <a mat-button class="pointer text-lg-white" [routerLink]="menu.link">
                {{ isThai ? menu.labelTh : menu.labelEn }}</a>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-4 py-2 mb-3">
          <h4 class="header-text">{{'Services' | translate }}</h4>
          <ng-container *ngFor="let menu of services">
            <div class="mb-2">
              <a mat-button class="pointer text-lg-white" [routerLink]="menu.link">
                {{ isThai ? menu.labelTh : menu.labelEn }}</a>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-4 py-2 mb-3">
          <h4 class="header-text">{{'Contact Info' | translate }}</h4>
          <span class="header-text">{{'Phone' | translate }}: </span><span>{{contact.phone}}</span><br>
          <span class="header-text">{{'Fax' | translate }}: </span><span>{{contact.fax}}</span><br>
          <span class="header-text">{{'Email' | translate }}: </span><span>{{contact.email}}</span><br>
          <!-- <span
          >เลขที่ 1 ถนนพระอาทิตย์ แขวงพระบรมมหาราชวัง <br />เขตพระนคร
          กรุงเทพมหานคร 10200 <br />โทรศัพท์ 0-2222-0206-9
          <br />saraban@ocs.go.th
        </span> -->
        </div>


      </div>
    </div>
  </div>

  <div class="layout-footer second py-4">
    <div class="container-lg layout-footer-container">
      2021© All right reserved by Martvilla
    </div>
  </div>
</div>
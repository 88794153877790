import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateUtil } from '../utils/translate.util';

@Injectable({
  providedIn: 'root',
})
export class ConfirmPopupService {
  constructor(
    private confirmationService: ConfirmationService
  ) { }

  ref!: DynamicDialogRef;
  clicked = false;

  confirm(config?: Confirmation) {
    this.clicked = false;
    return new Promise<null>(async (resolve, reject) => {

      this.confirmationService.confirm({
        message: await TranslateUtil.translateText(' '),
        header: await TranslateUtil.translateText(' '),
        icon: 'pi pi-exclamation-circle',
        acceptLabel: await TranslateUtil.translateText('ยืนยัน'),
        rejectLabel: await TranslateUtil.translateText('ยกเลิก'),
        accept: () => {
          if (!this.clicked) {
            this.clicked = true;
            resolve(null);
          }
        },
        reject: (type: ConfirmEventType) => {
          if (!this.clicked) {
            this.clicked = true;
            reject(type);
          }
        },
        ...config,
      });
    })

  }
}

import { DatePipe } from "@angular/common";
import { CryptoKeyConstant } from "../constants/crypto.constant";
import { EncryptDecryptUtil } from "../utils/encrypt-decrypt.util";
import { RandomUtil } from "../utils/random.util";

const WEB = 'WEB';
const UUID_KEY = 'startTime';
const SESSION_ID = 'sessionId';
let checkStringData: boolean = true;

export class RequestModel<T> {
    reqHeader: RequestHeaderModel;
    reqBody: T | any;
    username!: string;
    constructor(serviceName: string, body?: T) {
        /* get username form sessionStroage **/
        let usernameDecrypted = sessionStorage.getItem("username");
        checkStringData = true;
        if (usernameDecrypted) {
            usernameDecrypted = EncryptDecryptUtil.decryptMassage(usernameDecrypted)
            this.username = EncryptDecryptUtil.decryptAES(usernameDecrypted as string, CryptoKeyConstant.Authen);
        }
        this.reqHeader = new RequestHeaderModel(serviceName, this.username);
        this.reqBody = body;
    }
}

export class RequestHeaderModel {
    reqId: string = (new Date().getTime()).toString();
    reqChannel: string = WEB;
    reqDtm: string = new DatePipe('en-Us').transform(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS') as string;
    reqBy: string = "";
    serviceName: string;
    uuid: string;
    sessionId: string;

    constructor(serviceName: string, reqBy: string = 'unknow') {

        this.serviceName = serviceName;
        this.reqBy = reqBy;
        let uuid = window.localStorage.getItem(UUID_KEY)
        let sessionId = window.sessionStorage.getItem(SESSION_ID);

        if (!uuid) {
            uuid = RandomUtil.randomId();
            window.localStorage.setItem(UUID_KEY, uuid)
        }
        this.uuid = uuid;

        /* session id **/
        if (!sessionId) {
            sessionId = RandomUtil.randomId();
            window.sessionStorage.setItem(SESSION_ID, sessionId)
        }
        this.sessionId = sessionId;
    }
}


import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SafeHtmlModule } from './@core/pipe/safeHtml.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterBarComponent } from './@layout/footer-bar/footer-bar.component';
import { LayoutPublicComponent } from './@layout/layout-component/layout-public/layout-public.component';
import { TopBarConfigComponent } from './@layout/top-bar-config/top-bar-config.component';
import { TopBarComponent } from './@layout/top-bar/top-bar.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInitService } from './@core/services/app-init.service';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';
import {InputTextModule} from 'primeng/inputtext';
import { SubscribeFootnoteComponent } from './@layout/subscribe-footnote/subscribe-footnote.component';
import { ContactService } from './@core/services/contact.service';
import { LocalizedDatePipeMoule } from './@core/pipe/localizedDatePipe.module';
import { HttpConfigInterceptor } from './@core/utils/interceptor.util';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import {ScrollTopModule} from 'primeng/scrolltop';
import { DateAgoPipeMoule } from './@core/pipe/dateAgo.module';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { IvyCarouselModule } from 'angular-responsive-carousel';

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name:'login' //you can use any name here
}; 

registerLocaleData(localeTh);
export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    FooterBarComponent,
    LayoutPublicComponent,
    TopBarConfigComponent,
    SubscribeFootnoteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LocalizedDatePipeMoule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlModule,
    DateAgoPipeMoule,
    NgxSpinnerModule,
    HttpClientModule,
    SlideMenuModule,
    SelectButtonModule,
    ButtonModule,
    SplitButtonModule,
    DropdownModule,
    DialogModule,
    InputTextModule,
    ConfirmDialogModule,
    CheckboxModule,
    SkeletonModule,
    ScrollTopModule,
    SocialLoginModule,
    IvyCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'th-TH' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '148563680098-dapaje0i2kcfnqgu63mb15k9juquhqqo.apps.googleusercontent.com',
              googleLoginOptions
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('5748888255224182')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    ContactService,
    ConfirmationService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
